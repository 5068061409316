/* global Swiper */

import './lineup.scss';

import * as dompack from 'dompack';
import $ from 'jquery';
import { TimelineLite } from 'gsap';

import LineupSlideshow from '../../lineupslideshow/lineupslideshow';

class Lineup {
  constructor($container) {
    this.$container = $container;

    this.$tabs = this.$container.find('.lineup__tab');

    this.$tabs.click((evt) => this.setTab(evt));

    this.$mobileSlideshow = new LineupSlideshow(this.$container.find('.emb-lineup__mobileslideshow'));
    this.$mobileSlideshow.setup(this.$container.find('.lineupslideshow').first());
  }

  setTab(evt) {
    evt.preventDefault();

    const selectedtab = evt.target.closest(".lineup__tab");
    dompack.qSA(selectedtab.closest(".emb-lineup"), ".emb-lineup__bottomlink--sethref").forEach(link =>
    {
      link.href = selectedtab.dataset.bottomlink;
    });


    let $tab = $(evt.target);
    if ($tab.hasClass('selected'))  //WARNING will break if lineup__tab ever contains subelements.
      return;

    let tabIndex = parseInt($tab.data('tab'));
    this.$tabs.removeClass('selected').eq(tabIndex).addClass('selected');
    $tab.closest('.emb-lineup').get(0).removeAttribute('data-aos');

    // check whether we need to animate for desktop or mobile
    let isDesktop = this.$container.find('.emb-lineup__categories').first().is(':visible');
    if (isDesktop) {
      let tl = new TimelineLite();

      let $tireContainers = this.$container.find('.emb-lineup__tirecontainer');
      let $currentCategory = this.$container.find('.emb-lineup__category.enabled');
      let $enableCategory = this.$container.find('.emb-lineup__category').eq(tabIndex);

      tl.to($tireContainers, .4, { opacity: 0 }) // hide container
        .to($currentCategory, 0, {css:{className:'-=enabled'}}) // disable current category
        .to($enableCategory, 0, {css:{className:'+=enabled'}}) // enable category
        .to($tireContainers, .05, { y: -30 }) // position container
        .staggerTo($tireContainers, .4, { opacity: 1, y: 0 }, 0.05); // fade in tires
    } else {
      let $enableSlideshow = this.$container.find('.lineupslideshow').eq(tabIndex);
      this.$mobileSlideshow.enable($enableSlideshow);
    }
  }
}

dompack.onDomReady(() => {
  $('.emb-lineup').each(function() {
    new Lineup($(this));
  });
});
