import * as dompack from 'dompack';
import * as domcookie from 'dompack/extra/cookie';
import * as geoip from '@mod-publisher/js/analytics/geoip';
import * as whintegration from '@mod-system/js/wh/integration';
import getTid from "@mod-tollium/js/gettid";
import "./international.scss";

var tirewarningdialog;
let countrycodepromise = geoip.getCountryCode();
let forced_geoipcountry  = new URL(location.href).searchParams.get('geoipcountry');
if(forced_geoipcountry)
  forced_geoipcountry = forced_geoipcountry.toUpperCase();

countrycodepromise.then(code => window.dataLayer.push({countrycode_geoip: code}));

if(forced_geoipcountry)
{
  //force all links to keep this variable
  window.addEventListener('click', evt =>
  {
    let ahref = evt.target.closest('a[href]');
    if(ahref)
      ahref.href += (ahref.href.includes('?')?'&':'?') + 'geoipcountry=' + encodeURIComponent(forced_geoipcountry);
  }, true);
}

export function navigateTo(link)
{
  if(forced_geoipcountry)
    link += (link.includes('?')?'&':'?') + 'geoipcountry=' + encodeURIComponent(forced_geoipcountry);
  location.href = link;
}

async function doCountryChecks()
{
  let sessioncountry = domcookie.read("cooper-countrycode");
  if(window.dataLayer)
    window.dataLayer.push({ countrycode_user: sessioncountry });

  let geoipcountry = (forced_geoipcountry || await countrycodepromise).toUpperCase();

  if(geoipcountry && whintegration.config.obj.master_market_mapping && !whintegration.config.obj.master_market_mapping.includes(geoipcountry))
  {
    // console.log({geoipcountry, marketmapping:whintegration.config.obj.master_market_mapping});
    let btn_current = <span class="button button--avon-non-skewed"><span class="button__title">{getTid("coopertires_sites:site.productunavailable_ok")}</span></span>;
    btn_current.addEventListener("click", ev => tirewarningdialog.close());

    //tire warning takes precedence over country warning
    tirewarningdialog = new cooperBotDialog(
      <div class="countrysuggestswitch" dir="ltr">
        <h3 class="heading2">{getTid("coopertires_sites:site.productunavailable")}</h3>
        <p class="normal">
          {getTid("coopertires_sites:site.productunavailable_inyourcountry")}
        </p>
        <div class="countrysuggestswitch__buttons">
          {btn_current}
        </div>
      </div>);
  }
  else
  {
    new cCountrySuggestSwitch(geoipcountry, sessioncountry);
  }
}

class cooperBotDialog
{
  constructor(contents)
  {
    let logo = whintegration.config.site.isavon ? "avon-logo-small.2x.png": "cooper-logo-small.2x.png";
    this.node = <div id="cooperbot">
                    <img src={ whintegration.config.imgroot +logo} alt="" class="smalllogo" />
                    {contents}
                  </div>;
    document.body.appendChild(this.node);

    this.closefn = ev => this.close(ev);
    document.body.addEventListener("click", this.closefn);
  }

  close(ev)
  {
    if( ev && dompack.closest(ev.target,"#cooperbot") )
      return;

    document.body.removeEventListener("click", this.closefn);
    this.node.parentNode.removeChild(this.node);
  }
}

class cCountrySuggestSwitch
{
  constructor(geoipcountry, sessioncountry)
  {
    let currentsite = whintegration.config.site.countrysites.filter(rec => rec.isselected)[0];

    //Try direct match first
    let suggestsite = whintegration.config.site.countrysites.filter(rec => rec.markets.includes(geoipcountry))[0];

    if(currentsite.flagcode == 'arableague')
    {
      suggestsite = whintegration.config.site.countrysites.filter(rec => rec.is_eu)[0]; //we ALWAYS suggest EU site on the ME site (#390)
    }
    else
    {
      if(currentsite.flagcode == sessioncountry) //you are on the site you explicitly wanted!
        return;

      if(suggestsite && suggestsite.isselected) //we're already at that site!
        return;

      if(!suggestsite && whintegration.config.site.fallback_eu)
        suggestsite = whintegration.config.site.countrysites.filter(rec => rec.is_eu)[0];
    }

    console.log({geoipcountry, sessioncountry, currentsite, suggestsite});

    if(!suggestsite)
      return;

    let btn_other = <span class="button button--avon-non-skewed"><span class="button__title">{suggestsite.texts.gotosite}</span></span>;
    btn_other.addEventListener("click", ev => this.setSite(suggestsite.flagcode, suggestsite.link));

    let btn_current = <span class="button button--avon-non-skewed"><span class="button__title">{suggestsite.texts.stay}</span></span>;
    btn_current.addEventListener("click", ev => this.setSite(currentsite.flagcode));

    let infonode =
      <div class="countrysuggestswitch" dir="ltr">
        <h3 class="heading2">{suggestsite.texts.title}</h3>
        <p class="normal">
          {suggestsite.texts.text}
          <img src={suggestsite.flagwave} class="countryflag" />
        </p>
        <div class="countrysuggestswitch__buttons">
          {btn_other}
          {btn_current}
        </div>
      </div>;

    this.dialog = new cooperBotDialog(infonode);
  }

  setSite( code, link )
  {
    domcookie.write("cooper-countrycode", code.toLowerCase())

    this.dialog.close();
    if( link )
      navigateTo(link);
  }
}

dompack.onDomReady(doCountryChecks);
