import './regionsfoldout.scss';
import * as dompack from "dompack";

import $ from 'jquery';
require('../../web/jquery-ui/jquery-ui.min.js');

dompack.onDomReady(() => {
  $('.regionsfoldout__accordion').accordion({
    heightStyle: "content",
    animate: 250,
    collapsible: true,
    active: false,
  });
});
