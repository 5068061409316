//load GTM for  implementing delayed GTM launch and forwarding PXL events
import * as dompack from "dompack";
import * as gtm from '@mod-publisher/js/analytics/gtm.es';
import * as whintegration from '@mod-system/js/wh/integration';
import * as pxl from "@mod-consilio/js/pxl";
import "@mod-publisher/js/analytics/gtm.es";

export function getSearchQuery()
{
  return dompack.qS("#searchcarbymodel").value;
}

export function triggerEvent(event, vars, options)
{
  pxl.sendPxlEvent(event, vars, options);
  if(whintegration.config.server >= 42302)
    return;

  //TODO below can be replaced with sendPxlEventToDataLayer in 4.23+
  let datalayervars = {};
  if(vars)
    Object.keys(vars).forEach(key =>
    {
      if(key.startsWith('ds_') || key.startsWith('dn_'))
        datalayervars[key.substr(3)] = vars[key];
      else if(key.startsWith('db_'))
        datalayervars[key.substr(3)] = vars[key] ? "true" : "false";
      else
        console.error("KEY?",key);
    });
  window.dataLayer.push({...datalayervars, event: event });
}

let pagetype = 'other';
if(document.documentElement.classList.contains('page-home'))
  pagetype = 'home';
else if(document.documentElement.classList.contains('page-dealer-locator'))
  pagetype = 'find_store';
else if(document.documentElement.classList.contains('page-tireoverview'))
  pagetype = 'product_overview';
else if(document.documentElement.classList.contains('page-tiredetails'))
  pagetype = 'product_detail';
else if(document.documentElement.classList.contains('page-news'))
  pagetype = 'news';
else if(document.documentElement.classList.contains('page-news_article'))
  pagetype = 'news_article';

window.dataLayer.push( { dtap: whintegration.config.dtapstage });

let pageinfo = { ds_brand: whintegration.config.site.isavon ? "avon" : "cooper"
               , ds_country: whintegration.config.site.country
               , ds_pagetype: pagetype
               };

triggerEvent("coopertires:pageview", pageinfo);
