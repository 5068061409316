import './mobilemenu.scss';

import * as whintegration from '@mod-system/js/wh/integration';
import * as dompack from 'dompack';
import { closeAnyOverlay } from '../findoverlay/findoverlay';

import $ from 'jquery';

dompack.onDomReady(() => {
  $('.js-toggle-mobilemenu').click(function(evt) {
    evt.preventDefault();
    $(this).toggleClass('is-active');
    $('html').toggleClass('menu-open');
    closeAnyOverlay();
  });

  $('.js-mobilemenu-expandable').click(expandMenuItem);
});

function expandMenuItem(evt) {
  evt.preventDefault();

  $(this).parents('.p-links__items').toggleClass('expanded');
}
