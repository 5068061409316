import './header.scss';

import * as whintegration from '@mod-system/js/wh/integration';
import * as dompack from 'dompack';

// internal
import * as util from '../shared/js/utilities';

import { closeAnyOverlay } from '../findoverlay/findoverlay';

// vendor
import $ from 'jquery';

let pageHeaderBackgroundPosY = 0.0;

let pageHeaderScrollFactor = 20.0; // higher factor => slower movement
let pageHeaderContentsTransformFactor = 2.0; // higher factor => slower movement
let pageHeaderContentsOpacityFactor = 300.0; // higher factor => slower fadeout

// https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
// Test via a getter in the options object to see if the passive property is accessed
var supportsPassive = false;

try {
  var opts = Object.defineProperty({}, 'passive', {
    get: function() {
      supportsPassive = true;
    }
  });
  window.addEventListener("test", null, opts);
} catch (e) {}

dompack.onDomReady(() => {
  pageHeaderBackgroundPosY = parseFloat($('.pageheader').css('background-position-y'));
  if (!pageHeaderBackgroundPosY)
    pageHeaderBackgroundPosY = 50.0;

  if (document.documentElement.classList.contains('enable-parallax-header')) {
    document.documentElement.classList.add('apply-parallax-header');

    window.addEventListener('resize', ev => onResize());
    window.setTimeout(() => { onResize(); }, 100);
  }

  window.addEventListener('scroll', ev => onScroll(), supportsPassive ? { passive: true } : false);
  onScroll();

  $('.header__searchicon').click(toggleSearch);

  setupSubMenus();

  // $('.header__bottom-bar-subitems-container').first().addClass('show'); // enable for debugging/styling
});

function onResize() {
  let $pageheader = $('.pageheader');

  let pageHeaderHeight = parseInt($pageheader.get(0).getBoundingClientRect().height + 2);

  let $mobileHeader = $('.mobileheader');
  if (util.isAvonSite() && $mobileHeader && $mobileHeader.is(':visible')) {
    pageHeaderHeight = pageHeaderHeight - $mobileHeader.height();
  }

  $('.sitewrapper').css('padding-top', pageHeaderHeight);

  onScroll();
}

function onScroll(ev) {
  let scrollY = util.getScrollY();

  if (document.documentElement.classList.contains('enable-parallax-header')) {
    let newPos = 0.0;
    let scrollYPos = scrollY; // normalize by position when we started scrolling
    if (scrollYPos < 0)
      scrollYPos = 0;

    newPos = pageHeaderBackgroundPosY + scrollYPos/pageHeaderScrollFactor;
    if (newPos > 100.0) {
      newPos = 100;
    }

    let $pageheader = $('.pageheader');

    $('.pageheader').css('background-position-y', `${newPos}%`);

    let transformY = `translateY(${(scrollY) / (-1 * pageHeaderContentsTransformFactor)}px)`;

    $pageheader.find('.pageheader__video').css('transform', transformY);

    let $pageheaderContents = $('.pageheader__contents');
    $pageheaderContents.css({ 'transform': transformY,
                              'opacity': 1 - scrollY / pageHeaderContentsOpacityFactor
                            });
  }
}

function toggleSearch(evt) {
  evt.preventDefault();

  let willEnable = !$('html').hasClass('header-search-active');
  if (willEnable)
  {
    closeAnyOverlay();
    $('.header .c-search__input').focus();
  }

  $('html').toggleClass('header-search-active');
}

function setupSubMenus() {
  $('.header__bottom-bar-left-link--has-subitems').on('mouseover', function() {
    $(this).parents('.header__bottom-bar-left-link-container').find('.header__bottom-bar-subitems-container').addClass('show');
    $(this).addClass('header__bottom-bar-left-link--selected');
  });

  $('.header__bottom-bar-left-link-container--has-subitems').on('mouseleave', function() {
    $(this).find('.header__bottom-bar-subitems-container').removeClass('show');
    $(this).find('.header__bottom-bar-left-link').removeClass('header__bottom-bar-left-link--selected');
  });

  $('.header__bottom-bar-right-link--has-subitems').on('mouseover', function() {
    $(this).parents('.header__bottom-bar-right-link-container').find('.header__bottom-bar-subitems-container').addClass('show');
    $(this).addClass('header__bottom-bar-right-link--selected');
  });

  $('.header__bottom-bar-right-link-container--has-subitems').on('mouseleave', function() {
    $(this).find('.header__bottom-bar-subitems-container').removeClass('show');
    $(this).find('.header__bottom-bar-right-link').removeClass('header__bottom-bar-right-link--selected');
  });
}

