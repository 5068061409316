import './cookiebar.scss';

import * as dompack from 'dompack';
import * as util from '../shared/js/utilities';
import * as domcookies from 'dompack/extra/cookie.es';

function initCookies()
{
  let cookieBarCookie = domcookies.read('cooper_cookie_bar');
  if (!cookieBarCookie && document.querySelector(".koekjesbalk"))
    document.documentElement.classList.add('show-cookie-bar');

  dompack.qSA('.cookiebar__close').forEach(cookiebar => cookiebar.addEventListener('click', evt=>
  {
    evt.preventDefault();
    document.documentElement.classList.remove('show-cookie-bar');
    domcookies.write('cooper_cookie_bar', '1', {duration: 365 });
  }));
}

dompack.onDomReady(initCookies);

window.resetCookieBar = function()
{
  domcookies.remove('cooper_cookie_bar');
  initCookies();
}
