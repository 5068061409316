import './forms.scss';

// WebHare
import * as dompack from 'dompack';

// WebHare forms
import UploadField from '@mod-publisher/js/forms/fields/upload';
import ImgEditField from '@mod-publisher/js/forms/fields/imgedit';
import RTDField from '@mod-publisher/js/forms/fields/rtd';
import {cSplitDateInput, cSplitTimeInput} from '../splitdatetimeinput';

import $ from 'jquery';
import * as forms from '@mod-publisher/js/forms';
forms.setup({ validate: true });

dompack.onDomReady(() => {
  if (document.documentElement.classList.contains('labelsinfields'))
    setupFormAnimations();
});

function setupFormAnimations() {
  $('.wh-form input[type="text"], .wh-form input[type="password"], .wh-form input[type="email"], .wh-form textarea, select').each(function() {
    let $container, activeClass;

    $container = $(this).closest('.wh-form__fieldgroup');
    activeClass = 'wh-form__fieldgroup--active';

    $(this).on('focus', function() {
      $container.addClass(activeClass);
    });

    $(this).on('blur', function() {
      $container.toggleClass(activeClass, $(this).val() !== '');
    });

    if ($(this).val() !== '') {
      $container.addClass(activeClass);
    }
  });
}

dompack.register(".wh-form__upload", node => new UploadField(node));
dompack.register(".wh-form__imgedit", node => new ImgEditField(node));
dompack.register(".wh-form__rtd", node => new RTDField(node, { rtdoptions: { allowundo: false }
                                                             , hidebuttons: ['table']
                                                             }));
dompack.register(".wh-styledinput input[type=time]", node => new cSplitTimeInput(node));
dompack.register(".wh-styledinput input[type=date]", node => new cSplitDateInput(node));

//forms.registerHandler("newsletter", form => new forms.FormBase(form));
