import './tireoverview.scss';
import './tiredetails.es';

import * as dompack from 'dompack';
import JSONRPC from "@mod-system/js/net/jsonrpc";
import * as CarDBService from "@mod-coopertires_sites/js/cardbservice.rpc.json";
import getTid from "@mod-tollium/js/gettid";
import "../../cooper.lang.json";
import { updateSelectOptions, applyCustomStylePulldown } from "../../shared/js/utilities";
import * as datalayer from '../../shared/js/datalayer';
import { navigateTo } from '../../shared/international';
import * as whintegration from '@mod-system/js/wh/integration';
//vendor
import $ from 'jquery';

var rpc;
var searchinfo;
var rimsize_inputs, tiresize_inputs, engine_inputs,
    cartype_inputs, tiretype_inputs,
    speedindex_input, roadtype_input,
    mcycletype_inputs,
    weather_inputs, surface_inputs, application_inputs, construction_inputs;
var speedindex_comp, roadtype_comp;
let updating_selects = false;

class SpeedIndexSlider
{
  constructor(fieldgroup)
  {
    if (!fieldgroup)
      throw new Error("Speed slider fieldgroup not found");
    this.fieldgroup = fieldgroup;
  }
}

class RoadTypeSlider
{
  constructor(fieldgroup)
  {
    if (!fieldgroup)
      throw new Error("Road type fieldgroup not found");
    this.fieldgroup = fieldgroup;

    // Hide the default input field
    let fieldline = this.fieldgroup.querySelector(".wh-form__fieldline");
    fieldline.style.display = "none";
    this.inputnode = fieldline.querySelector("input");

    // Create a new slider field
    this.slidernode =
      <input type="range"
             min="0"
             max="100"
             step="5"
             value="0"
             onchange={event => this.onRangeChange(event)}
      />;
    fieldline.parentNode.insertBefore(
      <div class="wh-form__fieldline">
        {this.slidernode}
      </div>, fieldline);

    // Show the fieldgroup
    this.fieldgroup.style.display = "block";
  }

  onRangeChange(event)
  {
    dompack.changeValue(this.inputnode, this.slidernode.value);
  }

  setVisible(visible)
  {
    dompack.closest(this.fieldgroup, ".p-searchblock").style.display = visible ? "block" : "none";
  }
}

dompack.onDomReady(() =>
{
  if (!document.documentElement.classList.contains('page-tireoverview'))
    return;

  rpc = new JSONRPC();
  searchinfo = JSON.parse(dompack.qS(".p-tireoverview").dataset.searchinfo);

  for (let button of dompack.qSA(".toggle-sidebar"))
    button.addEventListener("click", event =>
    {
      event.preventDefault();
      document.documentElement.classList.add("show-mobile-filters");
    })
  dompack.qS(".p-sidebar__close").addEventListener("click", event =>
  {
    event.preventDefault();
    document.documentElement.classList.remove("show-mobile-filters");
  })

  if (searchinfo.type == "car")
  {
    if (searchinfo.cartype != "motorcycle")
    {
      rimsize_inputs = dompack.qSA("select[name='rimsize']");
      applyCustomStylePulldown($(rimsize_inputs), false, (evt) => { updateFilters(true, evt); });

      // for (let input of rimsize_inputs)
      //   input.addEventListener("change", updateFilters);

      tiresize_inputs = dompack.qSA("select[name='tiresize']");
      applyCustomStylePulldown($(tiresize_inputs), true, (evt) => { filterResults(true, evt); });

      // for (let input of tiresize_inputs)
      //   input.addEventListener("change", filterResults);
    }

    engine_inputs = dompack.qSA("select[name='engine_spec']");
    applyCustomStylePulldown($(engine_inputs), false, (evt) => { updateFilters(true, evt); });

    // for (let input of engine_inputs)
    //   input.addEventListener("change", updateFilters);
  }
  else
  {
    let vehicletype_input = dompack.qS("select[name='vehicletype']");
    if (vehicletype_input)
    {
      applyCustomStylePulldown($(vehicletype_input), true, (evt) =>
      {
        let baseurl = location.href.split("?")[0];
        if (vehicletype_input.value != "car_suv")
          navigateTo(`${baseurl}?cartype=${vehicletype_input.value}`);
        else
          navigateTo(baseurl);
      });
    }

    if ([ "motorsport", "racingbike"].includes(searchinfo.cartype))
    {
      rimsize_inputs = dompack.qSA("select[name='rimsize']");
      applyCustomStylePulldown($(rimsize_inputs), false, () => filterResults(true));
    }
  }

  cartype_inputs = dompack.qSA("input[name='cartype']");
  for (let input of cartype_inputs)
    input.addEventListener("change", () => filterResults(true));

  tiretype_inputs = dompack.qSA("input[name='tiretype']");
  for (let input of tiretype_inputs)
    input.addEventListener("change", () => filterResults(true));

  speedindex_input = dompack.qS("select[name='speedindex']");
  if (speedindex_input)
  {
    speedindex_comp = new SpeedIndexSlider(dompack.qS(".speedindex-slider"));

    // since we want to have a slider, this is probably just temporary:
    applyCustomStylePulldown($(speedindex_input), true, (evt) => { filterResults(true, evt); });

    // and should probably be replaced by:
    // speedindex_input.addEventListener("change", filterResults);
  }

  // FIXME: disabled for now
  // roadtype_input = dompack.qS("input[name='roadtype']");
  // roadtype_comp = new RoadTypeSlider(dompack.qS(".roadtype-slider"));
  // roadtype_input.addEventListener("change", filterResults);

  mcycletype_inputs = dompack.qSA("input[name='mcycle_type']");
  for (let input of mcycletype_inputs)
    input.addEventListener("change", evt => filterResults(true,evt));

  weather_inputs = dompack.qSA("input[name='weather']");
  for (let input of weather_inputs)
    input.addEventListener("change", evt => filterResults(true,evt));
  surface_inputs = dompack.qSA("input[name='surface']");
  for (let input of surface_inputs)
    input.addEventListener("change", evt => filterResults(true,evt));
  application_inputs = dompack.qSA("input[name='application']");
  for (let input of application_inputs)
    input.addEventListener("change", evt => filterResults(true,evt));
  construction_inputs = dompack.qSA("input[name='construction']");
  for (let input of construction_inputs)
    input.addEventListener("change", evt => filterResults(true,evt));

  dompack.qS(".p-sidebar__apply").addEventListener("click", evt => filterResults(true,evt));

  filterResults(false, null);
});

async function updateFilters(user_initiated, event)
{
  if (updating_selects)
    return;
  updating_selects = true;
  let lock = dompack.flagUIBusy();

  if (event.target.name == "engine_spec")
  {
    // Sync all engine selects
    for (let input of engine_inputs)
      if (input != event.target)
        input.value = event.target.value;
  }

  let query = {
    engine: engine_inputs[0].value
  , exclude_aftermarket: !whintegration.config.site.show_optional_tires
  };
  // This function is called when either engine_spec or rimsize changes. If engine_spec changes, don't take current rimsize
  // into account, as the rimsizes are updated.
  if (event.target.name == "rimsize")
  {
    // Sync all rimsize selects
    for (let input of rimsize_inputs)
      if (input != event.target)
        input.value = event.target.value;

    query.rim = event.target.value;
  }

  let result = await CarDBService.getCarSizeOptions(searchinfo.channel, searchinfo.car, query);
  if(!whintegration.config.site.show_optional_tires)
    result.dimensions = result.dimensions.filter(dim => dim[0] === '+');

//console.info(query,result);
  // Tire sizes are always updated
  let dimension_options = result.dimensions.map(dimension =>
  {
    let isdefaultsize = dimension[0] === "+";
    dimension = dimension.substr(1);
    return { title: (isdefaultsize ? getTid("coopertires_sites:site.serial") : getTid("coopertires_sites:site.aftermarket")).toUpperCase()
                    + ": "
                    + (dimension.indexOf("|") > 0 ? getTid("coopertires_sites:site.front_rear", ...dimension.split("|")) : dimension),
             value: dimension
           };
  });
  dimension_options.unshift({ title: getTid("coopertires_sites:site.alldimensions").toUpperCase(), value: "" });
  for (let input of tiresize_inputs)
    updateSelectOptions(input, dimension_options);

  // Rim sizes are only updated if engine_spec changed
  if (event.target.name == "engine_spec")
  {
    let rim_options = result.rims.map(rim =>
    {
      return { title: rim,
               value: rim
             };
    });
    rim_options.unshift({ title: getTid("coopertires_sites:site.allrimsizes").toUpperCase(), value: "" });
    for (let input of rimsize_inputs)
      updateSelectOptions(input, rim_options, false);
  }

  refreshPulldowns();

  lock.release();
  updating_selects = false;

  filterResults(user_initiated, event, result);
}

async function filterResults(user_initiated, event, result)
{
  if (updating_selects)
    return;

  let isInvalidCarSearch = searchinfo.type == "car" && searchinfo.car == "";

  document.getElementById("tireoverview-noresults").style.display = (isInvalidCarSearch ? "" : "none");
  if(document.getElementById("tireoverview-noresults-cartypes"))
    document.getElementById("tireoverview-noresults-cartypes").style.display = (isInvalidCarSearch ? "" : "none");

  if (isInvalidCarSearch)
  {
    document.getElementById("tireoverview-pagetitle").style.display = "none";
    return;
  }

  if (event && event.target.name == "tiresize")
  {
    // Sync all tiresize selects
    updating_selects = true;
    for (let input of tiresize_inputs)
      if (input != event.target)
        input.value = event.target.value;

    refreshPulldowns();

    updating_selects = false;
  }

  let query = {};
  if (searchinfo.type == "car")
  {
    if (searchinfo.cartype != "motorcycle")
    {
      query.rim = rimsize_inputs.length ? rimsize_inputs[0].value : '-';
      query.dimension = tiresize_inputs.length ? tiresize_inputs[0].value : '-';
    }
  }
  else if (["motorsport","racingbike"].includes(searchinfo.cartype))
  {
    if (rimsize_inputs && rimsize_inputs.length)
      query.rim = rimsize_inputs[0].value;
  }

  if (cartype_inputs.length > 0)
  {
    for (let input of cartype_inputs)
      if (input.checked)
        query.type = input.value;
    if (!query.type)
      query.type = searchinfo.cartype;
  }
  else
    query.type = searchinfo.cartype;

  for (let input of tiretype_inputs)
    if (input.checked)
      query.season = input.value;

  if (speedindex_input)
    query.speed = speedindex_input.value;

  let mcycletypes = [];
  for (let input of mcycletype_inputs)
    if (input.checked && input.value)
      mcycletypes.push(input.value);
  if (mcycletypes.length)
    query.mcycle_type = mcycletypes;

  for (let input of weather_inputs)
    if (input.checked && input.value)
      query.weather = input.value;
  for (let input of surface_inputs)
    if (input.checked && input.value)
      query.surface = input.value;
  for (let input of application_inputs)
    if (input.checked && input.value)
      query.application = input.value;
  for (let input of construction_inputs)
    if (input.checked && input.value)
      query.construction = input.value;

  // On mobile, filters are only applied after tapping the 'apply' button
  if (event && document.documentElement.classList.contains("show-mobile-filters") && !dompack.closest(event.target, ".p-sidebar__apply"))
    return;

  if (searchinfo.type == "car")
  {
    if ( (!query.rim || !query.dimension) && searchinfo.cartype != "motorcycle") //not relevant for motorcycle, coopertires/coopertires#400
      document.documentElement.classList.add("mobile-filters-required");
    else
      document.documentElement.classList.remove("mobile-filters-required");
  }


  // Only request masters if this filterResults wasn't called from updateFilters
  if (!result)
    result = await rpc.async("FindMasters", query);
  else
    result = { ...result, success: true };
  let datalayerquery = { ds_filter_engine: engine_inputs && engine_inputs.length ? engine_inputs[0].value : ""
                       , ds_overview_cartype: searchinfo.cartype
                       , ds_overview_car: searchinfo.car
                       , ds_overview_channel: searchinfo.channel
                       };
  Object.keys(query).forEach(key =>
  {
    if(typeof query[key] == "string")
      datalayerquery['ds_filter_' + key] = query[key];
  });

  document.documentElement.classList.remove("show-mobile-filters");

  let numResults = 0;

  if (!result.success)
    console.error(result);
  else
  {
    // convert integer to string
    let resultIds = result.masters.map(id => "" + id);
    for (let searchresult of dompack.qSA(".p-searchresult"))
    {
      if (resultIds.includes(searchresult.dataset.resultId))
      {
        ++numResults;
        searchresult.style.display = "";
      }
      else
        searchresult.style.display = "none";
      for (let link of searchresult.querySelectorAll("a.p-resultlink--update-vars"))
      {
        let url = new URL(link.href);
        url.searchParams.set("cartype", query.type);
        if (query.dimension)
          url.searchParams.set("dimension", query.dimension);
        else
          url.searchParams.delete("dimension", query.dimension);
        if (engine_inputs && engine_inputs.length && engine_inputs[0].value)
          url.searchParams.set("engine_spec", engine_inputs[0].value);
        else
          url.searchParams.delete("engine_spec");
        if (query.rim)
          url.searchParams.set("rim", query.rim);
        else
          url.searchParams.delete("rim");
        if (query.construction)
          url.searchParams.set("construction", query.construction);
        else
          url.searchParams.delete("construction");
        if (query.speed)
          url.searchParams.set("speed", query.speed);
        else
          url.searchParams.delete("speed");
        link.href = url.toString();
      }
    }

    if (numResults > 0)
      document.getElementById("tireoverview-pagetitle").textContent = getTid("coopertires_sites:site.find_tyres_results", numResults);

    document.getElementById("tireoverview-pagetitle").style.display = (numResults > 0 ? "" : "none");
    document.getElementById("tireoverview-noresults").style.display = (numResults > 0 ? "none" : "");
    if(document.getElementById("tireoverview-noresults-cartypes"))
      document.getElementById("tireoverview-noresults-cartypes").style.display = (numResults > 0 ? "none" : "");
  }

  datalayer.triggerEvent("coopertires:overview_mastersearch", { ...datalayerquery
                                                              , ds_mastersearchtype: user_initiated ? "user" : "initial"
                                                              , dn_nummasters: numResults
                                                              });
}

function refreshPulldowns() {
  $(rimsize_inputs).selectmenu('refresh');
  $(tiresize_inputs).iconselectmenu('refresh');
  $(engine_inputs).selectmenu('refresh');
}
