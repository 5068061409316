import * as dompack from 'dompack';
import "./examplecontainer.scss";

const typeintervalbase = 50;
const typeintervalrange = 250; //makes typing a bit less mechanic.
const timeaftertyping = 1500;
const typebetweentime = 700;

let currentnode, currentscroller, textholder
let timeout;
let texts;
let textoffset;
let typeoffset;

function startAnimation()
{
  //reset state
  texts = currentnode.dataset.examples.split('|');
  textoffset = Math.floor(Math.random() * texts.length);

  //start typing
  startTyping();
}

function startTyping()
{
  if(currentscroller)
    currentscroller.remove();

  currentscroller = <div class="findoverlay__example-scroll">
                      {textholder = <div class="findoverlay__example-value"></div>}
                    </div>;
  currentnode.append(currentscroller);

  typeoffset = 0;
  timeout = setTimeout(typeCharacter, typebetweentime);
}

function typeCharacter()
{
  let currenttext = texts[textoffset];

  if(++typeoffset < currenttext.length) //not at the end yet
  {
    textholder.textContent = currenttext.substr(0, typeoffset) + '_';
    timeout = setTimeout(typeCharacter, typeintervalbase + Math.random() * typeintervalrange);
  }
  else
  {
    textholder.textContent = currenttext;
    timeout = setTimeout(scrollAway, timeaftertyping);
  }
}

function scrollAway()
{
  //slide it up
  currentscroller.classList.add("findoverlay__example-scroll--scrollaway");
  textoffset = (textoffset+1) % texts.length;
  timeout = setTimeout(startTyping, typebetweentime);
}

export function checkAnimations()
{
  if(timeout)
  {
    clearTimeout(timeout);
    timeout = null;
  }

  currentnode = dompack.qS(".findoverlay.show .findoverlay__examplecontainer");
  if(currentnode)
    startAnimation();
}
