// WebHare
import * as whintegration from '@mod-system/js/wh/integration';
import '@mod-publisher/js/richcontent/all';
import '@mod-system/js/wh/integration';
import * as dompack from 'dompack';
import * as browser from 'dompack/extra/browser';

// main CSS
import './cooper.scss';
import './shared/css/typography.scss';
import './shared/bem/bem.scss';

// components
import './pageheader/pageheader.scss';
import './embeddedobjects/download/download.scss';
import './embeddedobjects/featuredlink/featuredlink.scss';
import './embeddedobjects/fourimages/fourimages.scss';
import './embeddedobjects/news/news.scss';
import './embeddedobjects/quote/quote.scss';
import './embeddedobjects/sponsorship/sponsorship.scss';
import './embeddedobjects/textimagecols/textimagecols.scss';
import './embeddedobjects/twoimages/twoimages.scss';
import './embeddedobjects/twocolumnstext/twocolumnstext.scss';

import 'font-awesome/css/font-awesome.min.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import './web/jquery-ui/jquery-ui.min.css';

// components
import './cookiebar/cookiebar';
import './embeddedobjects/embeddedobjects';
import './findoverlay/findoverlay';
import './footer/footer';
import './header/header';
import './lineupslideshow/lineupslideshow';
import './mobileheader/mobileheader';
import './mobilemenu/mobilemenu';
import './newsletter/newsletter';
import './pagehelper/pagehelper';
import './shared/forms/forms';
import './slideshow/slideshow';
import './sidebar/contactinformation/contactinformation';

// pages
import './pages/content/content';
import './pages/dealer_locator/dealer_locator.es';
import './pages/home/home';
import './pages/news/news.es';
import './pages/search/search';
import './pages/tireoverview/tireoverview';
// import './pages/press';
// import './pages/claimform';
// import './pages/dealerpage';
import './shared/js/datalayer';
import './shared/international';

import "./egghunt";

// vendor
import $ from 'jquery';
import Blazy from 'blazy';
import swal from 'sweetalert2';
import 'aos/dist/aos.css';
import AOS from 'aos';

// internal
import * as util from './shared/js/utilities';

if(browser.getPlatform() != 'ios')
  document.documentElement.className += ' non-touch';

dompack.onDomReady(() => {
  initBackgroundImage();
  initLazyLoading();
  initButtons();
  initCustomStyledPulldowns();
  handleNavigatorString();
  util.setExternalLinksTarget();
  util.setupImageTextBlocks();
  AOS.init();

  swal.setDefaults({ confirmButtonText: `<span class="button__title">OK</span>` });

  if (dompack.qS('.wh-errorinfo'))
    $('body').css('overflow', 'visible');
});

function initCustomStyledPulldowns() {
  // if we need onchange callbacks (when/if webtool forms support this), add it here as well
  $('.page-formwebtool select').selectmenu();
}

/*
function initStyledPulldown( selectnode )
{
  let wrappernode = <div class="wh-form__pulldown-styled" />;
  selectnode.parentNode.insertBefore(wrappernode, selectnode);
  wrappernode.appendChild(selectnode);
  wrappernode.appendChild(<span class="arrow fa fa-angle-down" />);
}
dompack.register(".wh-form.wh-styledinput select", node => initStyledPulldown( node ));
*/


function initBackgroundImage() {
  let $html = $('html');

  if ($html.hasClass('has-body-bg')) {
    $html.css('background-image', `url('${$html.data('bg-image-link')}')`)
         .css('background-position', $html.data('bg-image-pos'))
         .css('background-color', $html.data('bg-image-dominant-color'));
  }
}

function initLazyLoading() {
  if (document.documentElement.classList.contains('page-home')) // we do this manually on the homepage
    return;

  let options = {
    loadInvisible: true,
    successClass: 'b-lazy-loaded',
  };

  if (dompack.debugflags['debug']) {
    options.success = function(ele) { console.info('success', ele); };
    options.error = function(ele, msg) { console.info('error', ele, msg); };
  }

  new Blazy(options);
}

function initButtons() {
  //FIXME can we just NOT do this ?  4.22 will add wh-form__butonlabel to the inner <span> to help
  $('.button, .wh-form__button').each((idx, el) => {
    if ($(el).find('.button__title').length == 0 && !dompack.qS(el,'.wh-form__buttonlabel')) {
      let $buttonTitle = `<span class="button__title">${$(el).text()}</span>`;
      $(el).text('').append($buttonTitle);
    }
  });
}

function handleNavigatorString() {
  let isInternetExplorer = util.isInternetExplorer();
  $("html").toggleClass("isie", isInternetExplorer);
}
