import './slideshow.scss';

import * as dompack from "dompack";
import * as util from '../shared/js/utilities';

import $ from 'jquery';

let $lastActiveSlide;

// assuming 1 slideshow per page!
dompack.onDomReady(() => {
  if ($('.slideshow').length != 1)
    return;

  $lastActiveSlide = $('.slideshow .swiper-slide').first();

  let $swiperContainer = $('.slideshow .swiper-container').first();

  let numSlides = $swiperContainer.find('.swiper-slide').length;
  if (numSlides < 1)
    return;

  if (numSlides < 2) {
    $swiperContainer.find('.swiper-pagination').remove();
  }

  let screenIsMd = util.testBreakPoint('md');

  // we're only showing video on desktop (ipad landscape+), so no need to prepare on mobile
  if (!screenIsMd) {
    $('.slideshow__bgvideo source').each(function() {
      $(this).attr('src', $(this).data('src'));
    })

    let $firstSlide = $('.slideshow__item').first();
    if ($firstSlide.hasClass('is-video')) {
      $firstSlide.find('.slideshow__bgvideo').get(0).load();
      $firstSlide.find('.slideshow__bgvideo').get(0).play();
    }
  }

  setTimeout(() => {
    let swiper = new Swiper($swiperContainer.get(0), {
      direction: 'horizontal',
      loop: false,
      speed: 500,
      grabCursor: numSlides > 1,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      allowSlidePrev: numSlides > 1,
      allowSlideNext: numSlides > 1,
      on: {
        slideChangeTransitionStart: function() {
          // pause any playing video
          if ($lastActiveSlide.hasClass('is-video')) {
            let elVideo = $lastActiveSlide.find('video').get(0);
            if (elVideo)
              elVideo.pause();
          }

          $lastActiveSlide = $swiperContainer.find('.swiper-slide.swiper-slide-active').first();

          // autoplay if it's a video
          if ($lastActiveSlide.hasClass('is-video')) {
            let elVideo = $lastActiveSlide.find('video').get(0);
            elVideo.load();
            elVideo.currentTime = 0;
            elVideo.play();
          }
        }
      },
    });
  }, 100);
});
