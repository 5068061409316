import './faq.scss';

import * as dompack from "dompack";
import * as util from '../../shared/js/utilities';

import $ from 'jquery';
require('../../web/jquery-ui/jquery-ui.min.js');

dompack.onDomReady(() => {
  $('.faq__accordion').accordion({
    heightStyle: "content",
    animate: 250,
    collapsible: true,
    active: false,
  });
});
