/* global Swiper */

import "./home.scss";
import * as whintegration from '@mod-system/js/wh/integration';
import "../../cooper.lang.json";
import * as util from "../../shared/js/utilities";
import * as dompack from "dompack";
import * as browser from "dompack/extra/browser";
import * as datalayer from '../../shared/js/datalayer';
import '../../findoverlay/findoverlay';

import $ from 'jquery';
import Blazy from 'blazy';
import { TimelineLite } from 'gsap';

var selectedcartype = '';
let headerAnimationStarted = false;
let slideShowSwiper;

function setupExitPxl()
{
  var lastlink;

  //track last clicked link
  window.addEventListener("click", event =>
  {
    let linknode = dompack.closest(event.target,'[href],[type=submit]');
    if(linknode && linknode.href && !linknode.target)
      lastlink = linknode.href;
    else if(linknode && linknode.type=="submit") //the submit button
      lastlink = "submit";
  });

  window.addEventListener("beforeunload", () =>
  {
    let query = '';//getSizeQuery();
    datalayer.triggerEvent("coopertires:home_exit", { ds_cartype: selectedcartype
                                                    , ds_query: datalayer.getSearchQuery()
                                                    , ds_size: query.width||query.serie||query.rim ? `width=${query.width}, serie=${query.serie}, rim=${query.rim}` : ''
                                                    , ds_selwidth: query.width
                                                    , ds_selserie: query.serie
                                                    , ds_selrim: query.rim
                                                    , ds_exitroute: lastlink
                                                    }, { beacon: true });
    return browser.getName()=="ie" ? undefined : null; //dont trigger an unload message. IE requires undefined, safari requires null, others don't care
  });
}

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-home'))
    return;

  setupExitPxl();
  setupSlideshow();

  $('.js-scroll-first-page-down').click(scrollFirstPageDown);

  setupHeader();
});

function scrollFirstPageDown(evt) {
  evt.preventDefault();
  $('html, body').animate({ scrollTop: $(window).height() - $('.header__bottom-bar').height() }, 700);
}

function setupSlideshow() {
  let $swiperContainer = $('.p-head .swiper-container');
  if ($swiperContainer.length == 0)
    return;

  const interleaveOffset = 0.5;

  let numSlides = $swiperContainer.find('.swiper-slide').length;

  var swiperOptions = {
    loop: numSlides > 1,
    speed: 1000,
    grabCursor: numSlides > 1,
    watchSlidesProgress: true,
    mousewheelControl: true,
    keyboardControl: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    on: {
      progress: function() {
        let swiper = this;
        for (let i = 0; i < swiper.slides.length; i++) {
          let slideProgress = swiper.slides[i].progress;
          let innerOffset = swiper.width * interleaveOffset;
          let innerTranslate = slideProgress * innerOffset;
          swiper.slides[i].querySelector(".slide-inner").style.transform = "translate3d(" + innerTranslate + "px, 0, 0)";
        }
      },
      touchStart: function() {
        let swiper = this;
        for (let i = 0; i < swiper.slides.length; i++) {
          swiper.slides[i].style.transition = "";
        }
      },
      setTransition: function(speed) {
        let swiper = this;
        for (let i = 0; i < swiper.slides.length; i++) {
          swiper.slides[i].style.transition = speed + "ms";
          swiper.slides[i].querySelector(".slide-inner").style.transition = speed + "ms";
        }
      }
    }
  };

  slideShowSwiper = new Swiper($swiperContainer.get(0), swiperOptions);
}

function setupHeader() {
  // first preload everything needed for the animation
  let imagesLoaded = 0;

  // find out on how many images we need to wait
  let numImagesNeeded = $('.js-home-preload-image').length;

  // use blazy to preload all images
  let options = {
    loadInvisible: true,
    successClass: 'b-lazy-loaded',
  };

  // setup success callback, marking the images we've seen and starting the animation when doen
  options.success = function(el) {
    util.log('success', el);
    if (el.classList.contains('js-home-preload-image')) {
      util.log('1 more');
      imagesLoaded++;
    }

    util.log(`we loaded ${imagesLoaded}, we need ${numImagesNeeded}`);

    if (imagesLoaded >= numImagesNeeded) {
      util.log(`we got ${imagesLoaded}, we needed ${numImagesNeeded}`);
      window.setTimeout(showHeader, 250);
    }
  };

  options.error = function(el, msg) {
    console.error('blazy-error', el, msg);
    if (el.classList.contains('js-home-preload-image')) {
      // yikes, something went wrong with one of the needed images, let's just show the header then
      console.error('showing header');
      showHeader();
    }
  };

  let blazy = new Blazy(options);
  blazy.load(dompack.qSA('.js-home-preload-image'));

  // after 4 seconds, just start showing the header either way, we cannot wait any longer (also triggers the animation on critical error)
  window.setTimeout(showHeader, 4000);
}

function showHeader() {
  // gather elements
  let $sliderContainerOverlay = $('.p-head__slidercontaineroverlay');
  let $cta1Overlay = $('.p-head__cta--findtires .p-head__cta-overlay');
  let $cta2Overlay = $('.p-head__cta--findtiresbysize .p-head__cta-overlay');
  let $campaignCta = $('.p-campaign__cta-container');
  let $infoContainer = $('.p-campaign__infocontainer');
  let $header = $('.header');
  let $scrollIcon = $('.p-head__scrollicon');
  let $avonCtas = $('.c-avonctas');
  let $avonLockupImage = $('.p-head__lockupimg');
  let $video = $('.p-head__video');

  let skipHeaderAnimation = util.isInternetExplorer();

  if (skipHeaderAnimation) {
    if (whintegration.config.site.isavon) {
      $sliderContainerOverlay.css('width', 0);
      $infoContainer.css('opacity', 1);
      $avonCtas.css('opacity', 1);
      $avonLockupImage.css('opacity', 1);
      $header.css('opacity', 1);
    }
    else {
      $sliderContainerOverlay.css('width', 0);
      $cta1Overlay.css('height', 0);
      $cta2Overlay.css('height', 0);
      $campaignCta.css({ opacity: 1 });
      $infoContainer.css('opacity', 1);
      $header.css('opacity', 1);
      $scrollIcon.css('opacity', 1);
    }

    if (util.isInternetExplorer() && $video.length > 0) {
      let $videoContainer = $('.p-head__videocontainer');
      let posterImageSrc = $video.attr('poster');
      $videoContainer.css({ 'background': `no-repeat url('${posterImageSrc}')`,
                            'background-size': 'cover',
                            'background-position': 'center',
                          });
      $video.remove();
    }

    startSlideShowOrVideo();

    return;
  }

  if (headerAnimationStarted === true)
    return;

  // setup timeline lite, when complete start autoplaying the slideshow
  let tl = new TimelineLite({ onComplete: () => {
    startSlideShowOrVideo();
  }});

  // optionally tone the speed down somewhat // tl.timeScale(0.5);

  // setup the animation steps
  if (whintegration.config.site.isavon) {
    tl.to($sliderContainerOverlay, 0.4, { width: 0 })
      // .to($cta1Overlay, 0.4, { height: 0 }, 0.1)
      // .to($cta2Overlay, 0.4, { height: 0 }, 0)
      // .fromTo($campaignCta, 0.3, { opacity: 1, y: $campaignCta.height() }, { y: 0 }, 0.3)
      .fromTo($infoContainer, 0.4, { x: -150, opacity: 0 }, { x: 0, opacity: 1 }, 0.35)
      .fromTo($avonCtas, 0.5, { opacity: 0 }, { opacity: 1 }, 0.35)
      .to($avonLockupImage, 0.3, { opacity: 1 }, 0.30)
      .to($header, 0.3, { opacity: 1 }, 0.1);
      // .to($scrollIcon, 0.3, { opacity: 1}, '-=0.2');
  } else {
    tl.to($sliderContainerOverlay, 0.4, { width: 0 })
      .to($cta1Overlay, 0.4, { height: 0 }, 0.1)
      .to($cta2Overlay, 0.4, { height: 0 }, 0)
      .fromTo($campaignCta, 0.3, { opacity: 1, y: $campaignCta.height() }, { y: 0 }, 0.3)
      .fromTo($infoContainer, 0.4, { x: -150, opacity: 0 }, { x: 0, opacity: 1 }, 0.35)
      .to($header, 0.3, { opacity: 1 }, 0.1)
      .to($scrollIcon, 0.3, { opacity: 1}, '-=0.2');
  }

  // mark that we've started
  headerAnimationStarted = true;
}

function startSlideShowOrVideo() {
  if (slideShowSwiper) {
    slideShowSwiper.params.autoplay = { delay: 5000, reverseDirection: true };
    slideShowSwiper.autoplay.start();
  }

  let $video = $('.p-head__video');
  if ($video.length)
    $video.get(0).play();
}
