// Auto-generated language file from /opt/wh/whtree/modules/publisher/js/internal/rtd.lang.json
var registerTexts = require("@mod-tollium/js/gettid").registerTexts;
registerTexts("publisher","en",{"site":{"rtd":{"embedvideo":{"playbutton-aria":"Play video","unrecognized":"The URL is not recognized as a supported video URL","videourl":"Video URL"}}}});
registerTexts("publisher","es",{"site":{"rtd":{"embedvideo":{"playbutton-aria":"Play video","unrecognized":"The URL is not recognized as a supported video URL","videourl":"Video URL"}}}});
registerTexts("publisher","de",{"site":{"rtd":{"embedvideo":{"playbutton-aria":"Play video","unrecognized":"The URL is not recognized as a supported video URL","videourl":"Video URL"}}}});
registerTexts("publisher","fr",{"site":{"rtd":{"embedvideo":{"playbutton-aria":"Play video","unrecognized":"The URL is not recognized as a supported video URL","videourl":"Video URL"}}}});
registerTexts("publisher","it",{"site":{"rtd":{"embedvideo":{"playbutton-aria":"Play video","unrecognized":"The URL is not recognized as a supported video URL","videourl":"Video URL"}}}});
registerTexts("publisher","ar",{"site":{"rtd":{"embedvideo":{"playbutton-aria":"Play video","unrecognized":"The URL is not recognized as a supported video URL","videourl":"Video URL"}}}});
registerTexts("publisher","nl",{"site":{"rtd":{"embedvideo":{"playbutton-aria":"Speel video af","unrecognized":"De URL is niet herkend als een ondersteunde video URL","videourl":"Video URL"}}}});
// Adding dependency: /opt/wh/whtree/modules/publisher/language/default.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/es.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/default.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/de.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/default.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/fr.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/default.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/it.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/default.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/ar.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/default.xml
// Adding dependency: /opt/wh/whtree/modules/publisher/language/nl.xml
