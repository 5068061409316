import './newsletter.scss';

// WebHare
import * as whintegration from '@mod-system/js/wh/integration';
import '@mod-newsletter/js/integration';
import * as dompack from "dompack";

// vendor
import $ from 'jquery';

window.addEventListener('wh:newsletter-subscribed', (evt) => {
  $(evt.target).closest('.newsletter').addClass('sent');
});
