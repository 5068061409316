import './carousel.scss';

import * as dompack from "dompack";

import $ from 'jquery';

dompack.onDomReady(() => {
  if ($('.carousel').length == 0)
    return;

  $('.carousel').each((idx, el) => {
    let $swiperContainer = $(el).find('.swiper-container');

    let numSlides = $swiperContainer.find('.swiper-slide').length;
    if (numSlides < 2) {
      $swiperContainer.parents('.carousel').find('.swiper-pagination, .swiper-button-prev, .swiper-button-next').css('display', 'none');
      return;
    }

    setTimeout(() => {
      let swiper = new Swiper($swiperContainer.get(0), {
        direction: 'horizontal',
        loop: numSlides > 1,
        speed: 500,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
      });
    }, 150);
  });
});
