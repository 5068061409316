import './relatedarticles.scss';

import * as dompack from "dompack";
import * as util from '../../shared/js/utilities';

import $ from 'jquery';

dompack.onDomReady(() => {
  $('.relatedarticles').each((idx, el) => {
    let $swiperContainer = $(el).find('.swiper-container');

    let numSlides = $swiperContainer.find('.swiper-slide').length;

    if (numSlides <= 2)
      $(el).addClass('no-slide');

    let screenIsSm = util.testBreakPoint('sm');

    let swiper = new Swiper($swiperContainer.get(0), {
      direction: 'horizontal',
      loop: numSlides > 2,
      speed: 500,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      slidesPerView: screenIsSm ? 1 : 2, // FIXME: change on window resize
      spaceBetween: 30,
    });
  });
});
