import './photoalbum.scss';

import * as dompack from "dompack";
import * as util from '../../shared/js/utilities';

import $ from 'jquery';

dompack.onDomReady(() => {
  if ($('.photoalbum').length == 0)
    return;

  let screenIsMd = util.testBreakPoint('md');

  $('.photoalbum').each((idx, el) => {
    let galleryThumbs;

    let numSlides = $(el).find('.photoalbum__gallery-top .swiper-slide').length;

    let galleryTopOptions = {
      navigation: {
        nextEl: screenIsMd ? $(el).find('.js-photoalbum-mobile-next').get(0) : $(el).find('.js-photoalbum-next').get(0),
        prevEl: screenIsMd ? $(el).find('.js-photoalbum-mobile-prev').get(0) : $(el).find('.js-photoalbum-prev').get(0),
      },
      grabCursor: true,
    };

    if (screenIsMd) {
      galleryTopOptions.loop = true;
    } else {
      galleryTopOptions.on = {
        slideChangeTransitionStart: function() {
          let activeIndex = this.activeIndex;
          $(galleryThumbs.slides).removeClass('is-selected');
          $(galleryThumbs.slides).eq(activeIndex).addClass('is-selected');
          galleryThumbs.slideTo(activeIndex,500, false);

          updateNavButtonsState($(el), this.activeIndex, numSlides);
        }
      };
    }

    let galleryTop = new Swiper($(el).find('.photoalbum__gallery-top'), galleryTopOptions);

    let slidesPerView = 3;
    if (document.documentElement.classList.contains('page-press'))
      slidesPerView = 2;

    galleryThumbs = new Swiper($(el).find('.photoalbum__thumbs'), {
      spaceBetween: 10,
      slidesPerView: slidesPerView,
      on: {
        click: function (swiper, event) {
          let clicked = this.clickedIndex;
          // console.log(clicked);
          $(this.slides).removeClass('is-selected');
          $(this.clickedSlide).addClass('is-selected');
          galleryTop.slideTo(clicked, 500, false);

          updateNavButtonsState($(el), this.clickedIndex, numSlides);
        }
      }
    });

    if (numSlides <= 3)
      $(el).find('.photoalbum__navigation').css('display', 'none');
  });
});

function updateNavButtonsState($photoalbum, idx, numSlides) {
  $photoalbum.find('.js-photoalbum-prev').toggleClass('disabled', idx == 0);
  $photoalbum.find('.js-photoalbum-next').toggleClass('disabled', idx >= numSlides - 1);
}
