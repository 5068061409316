/* global google */

import './dealer_locator.scss';

// WebHare
import * as whintegration from '@mod-system/js/wh/integration';
import * as dompack from 'dompack';
import domtemplate from '../../shared/js/domtemplate';
import JSONRPC from "@mod-system/js/net/jsonrpc";
import getTid from "@mod-tollium/js/gettid";
import { moveLatLng } from "./geo";
import "../../cooper.lang.json";
import * as util from '../../shared/js/utilities';
import * as datalayer from '../../shared/js/datalayer';
import * as findoverlay from '../../findoverlay/findoverlay';

// vendor
import $ from 'jquery';
import swal from 'sweetalert2';

var map;
var searchResultsControl;
var dealerIcon;
var ambassadorIcon;
var clubCooperIcon;
var clusterIcon;
var dealerInfoWindow;
var geocoder;
var categories = [];
var rpc;
var activemarkers = [];
var markerdataloaded = false;
var __updateleftpanel  = false;
var __referencelatlng = null;
var __querycheck = "";
var __resultfoldoutnode;

const range = 15; // Search within 15 km of user location
const maxResults = 15;
const resultsPerPage = 3;

// The Google Maps 'control' showing the search results
class SearchResultsControl
{
  constructor(div, map)
  {
    this.control = document.getElementById("dealerresults");

    this.container = this.control.querySelector(".p-dealerresults-container");
    this.container.addEventListener("click", event => this.onClickResult(event));

    this.navPrev = this.control.querySelector(".p-dealerresults-prev");
    this.navPrev.addEventListener("click", event => this.onPrevPage(event));
    this.navNext = this.control.querySelector(".p-dealerresults-next");
    this.navNext.addEventListener("click", event => this.onNextPage(event));
    this.navCount = this.control.querySelector(".p-dealerresults-count");

    this.map = map;
    this.resultDivs = [];
  }

  showResults(results, selectId)
  {
    // Clear markers and result divs array

    this.resultDivs = this.resultDivs.filter(div =>
    {
      return false;
    });

    dompack.empty(this.container);
    if( __resultfoldoutnode )
    { //remove results details(foldout)
      __resultfoldoutnode.parentNode.removeChild(__resultfoldoutnode);
      __resultfoldoutnode = null;
    }

    if (results && results.length)
    {
      this.navPrev.parentNode.style.display = "";

      let selectDiv = null;
      // Create divs for all results
      for (let result of results)
      {
        result.distance = util.formatDistance(result.distance, whintegration.config.site.use_imperial);

        let resultDiv = domtemplate.instantiate(document.getElementById("dealer-result"), result).firstChild;
        if( result.image )
        {
          let imgnode = resultDiv.querySelector(".p-dealerresult__logo > img");
          if( imgnode )
          {
            imgnode.parentNode.classList.add("custom");
            imgnode.src = result.image.normal;
            imgnode.srcset = result.image.normal + " 1x," + result.image.retina + " 2x";
            imgnode.width = 60;
          }
        }

        if (result.id === selectId)
          selectDiv = resultDiv;
        resultDiv.marker = result.marker;
        this.resultDivs.push(resultDiv);
      }
      // Show the first page of results
      this.showResultsPage(0);
      this.control.style.display = "block";
      google.maps.event.trigger(this.map, "resize");

      if (selectDiv)
        this.showDealerInfoWindow(selectDiv,"list");
    }
    else
    {
      // No results to show, hide full control for cooper
      //  if avon (which has category filtering, only hide prev/next
      if( !whintegration.config.site.isavon )
        this.control.style.display = "none";
      else
        this.navPrev.parentNode.style.display = "none";
    }
  }

  showResultsPage(page)
  {
    this.container.textContent = "";
    for (let i = page * resultsPerPage; i < this.resultDivs.length && i < (page + 1) * resultsPerPage; ++i)
    {
      this.resultDivs[i].classList.remove("p-dealerresult--selected");
      this.container.appendChild(this.resultDivs[i]);
    }

    this.curPage = page;
    let numPages = Math.ceil(this.resultDivs.length / resultsPerPage);
    this.navCount.textContent = `${this.curPage + 1}/${numPages}`;

    $(this.navPrev).toggleClass('show', this.curPage > 0);
    $(this.navNext).toggleClass('show', this.curPage < (numPages - 1));
  }

  onPrevPage(event)
  {
    event.preventDefault();
    this.showResultsPage(this.curPage - 1);
  }

  onNextPage(event)
  {
    event.preventDefault();
    this.showResultsPage(this.curPage + 1);
  }

  onClickResult(event)
  {
    let selectednode = dompack.closest(event.target, ".p-dealerresult");
    if( !selectednode || dompack.closest(event.target, ".p-dealerresult__details") )//ignore click on details (foldout)
      return;

    if( dompack.closest(event.target,".p-dealerresult__distance") || dompack.closest(event.target,".p-dealerresult__address") )
    { //Just scroll to map if mobile mode
      if( isMobileLayout() )
      {
        scrollToMap();
        return;
      }
    }

    event.preventDefault();

    this.showDealerInfoWindow(selectednode, "list");
  }

  setDealerDetailsFoldout(dealer)
  {
    dealer.classList.toggle("p-dealerresult--showdetails");

    if( __resultfoldoutnode )
    {
      if( __resultfoldoutnode.dataset.id != dealer.dataset.id )
      {
        __resultfoldoutnode.parentNode.classList.remove("p-dealerresult--showdetails");
        dompack.empty(__resultfoldoutnode);
      }
    }
    else
      __resultfoldoutnode = <div class="p-dealerresult__details" />;//

    if( !__resultfoldoutnode.dataset.id || __resultfoldoutnode.dataset.id != dealer.dataset.id )
    {
      __resultfoldoutnode.dataset.id = dealer.dataset.id;
      __resultfoldoutnode.classList.add("loading");
      __resultfoldoutnode.appendChild(<i class="fa fa-spinner fa-spin fa-fw"></i>);//
    }

    dealer.appendChild(__resultfoldoutnode);
  }

  showDealerInfoWindow(dealer, source)
  {
    if (!dealer)
      return;

    this.setDealerDetailsFoldout(dealer);

    doShowDealerInfoWindow(dealer.dataset.id, source, false);
  }
}

function isMobileLayout()
{
  let resultsel = document.getElementById('dealerresults');
  let mapel = document.getElementById('dealer-locator-map');
  return resultsel.clientWidth == mapel.clientWidth;
}

function doShowDealerInfoWindow(dealerid, source, force, dealerdata)
{
  dealerid = parseInt(dealerid);

  let ismobilelayout = isMobileLayout();

  //click on marker scroll to results if mobile
  let dealernode;
  if( source == "map" && ismobilelayout )
  {
    let activeidx = -1;
    for( let i = 0; i < searchResultsControl.resultDivs.length; ++i )
    {
      if( 1*searchResultsControl.resultDivs[i].dataset.id == dealerid )
      {
        activeidx = i;
        dealernode = searchResultsControl.resultDivs[i];
        break;
      }
    }

    if( activeidx > -1 )
    {
      let pagenr = ~~(activeidx / resultsPerPage);
      if( pagenr != searchResultsControl. curPage )
        searchResultsControl.showResultsPage(pagenr);

      searchResultsControl.setDealerDetailsFoldout(dealernode);

      scrollToMap(true, dealernode);
    }
    else
    { //Dealer not in current resultlist
      //We need to load new dealers in results list
      __referencelatlng = dealerdata.marker.position;//set selected position to current marker position
      searchDealersWithinBounds(dealerid,true);
      scrollToMap(true);//scroll to top of results
      return;
    }

  }

  if( dealerInfoWindow.dealerId == dealerid )
   return;//already open

  let dealerlistnode = dompack.qS(`.p-dealerresult[data-id="${dealerid}"]`);

  let latlng;
  if( source == "map" )
    latlng = new google.maps.LatLng(dealerdata.lat, dealerdata.lng);
  else if(dealerlistnode)
    latlng = new google.maps.LatLng(dealerlistnode.dataset.lat, dealerlistnode.dataset.lng);

  // Slide the position into view
  map.panTo(latlng);

  dealerInfoWindow.setDealerInfo( dealerid, latlng, force);
  dealerInfoWindow.setMap(map);

  // Highligh the selected dealer in the results list
  for (let result of document.querySelectorAll(".p-dealerresult"))
  {
    if ( parseInt(result.dataset.id) == dealerid )
      result.classList.add("p-dealerresult--selected");
    else
      result.classList.remove("p-dealerresult--selected");
  }

  updateDealerInfoWindow(dealerid, source, dealerInfoWindow);

  if (util.testBreakPoint('md')) {
    map.panBy(170, 50);
  }
}

// The DealerInfoWindow class is wrapped within a function, which is called after Google Maps is laoded and
// google.maps.OverlayView is available
function createDealerInfoWindow()
{
  class DealerInfoWindow extends google.maps.OverlayView
  {
    constructor()
    {
      super();
      this.anchorDiv = <div style="position:absolute;" />;
    }

    setDealerInfo(dealerid, latlng, force)
    {
      // Remove the current info
      if (this.dealerDiv)
      {
        this.anchorDiv.removeChild(this.dealerDiv);
        this.dealerDiv = null;
      }

      // Show the new info (do nothing if the marker is clicked twice, so the first click will show the info and the second
      // click will hide it again)
      if (!!force || dealerid != this.dealerId)
      {
        this.dealerId = dealerid;
        this.dealerDiv = domtemplate.instantiate(document.getElementById("dealer-info-loading"), {}).firstChild;

        this.position = latlng;
      }
      else
      {
        // Reset dealer id if clicked twice, so the next click trigger the info window again
        this.dealerId = null;
      }

      return this.dealerDiv;
    }

    onAdd()
    {
      if (this.anchorDiv)
        this.getPanes().floatPane.appendChild(this.anchorDiv);
    }

    onRemove()
    {
      if (this.anchorDiv && this.anchorDiv.parentElement)
      {
        this.anchorDiv.parentElement.removeChild(this.anchorDiv);
      }
    }

    draw()
    {
      if (this.dealerDiv)
      {
        var pos = this.getProjection().fromLatLngToDivPixel(this.position);
        this.anchorDiv.style.left = pos.x + 'px';
        this.anchorDiv.style.top = pos.y + 'px';
        this.anchorDiv.appendChild(this.dealerDiv);
      }
    }
  }
  return new DealerInfoWindow();
}

dompack.onDomReady(async () => {
  if (!document.documentElement.classList.contains('page-dealer-locator'))
    return;

  await util.googleMaps;

  let container = document.getElementById('google-maps-container');
  let iconbase = whintegration.config.imgroot + 'pins/' + (whintegration.config.site.isavon ? 'avon' : 'cooper');
  if (container && container.parentNode.dataset.dealerIcon)
  {
    dealerIcon =    { url: iconbase + '-dealer.2x.png', scaledSize: new google.maps.Size(23, 41) };
    ambassadorIcon = { url: iconbase + '-ambassador.2x.png', scaledSize: new google.maps.Size(23, 41) };
    clubCooperIcon = { url: iconbase + '-clubcooper.2x.png', scaledSize: new google.maps.Size(23, 41) };
  }

  clusterIcon = { url: iconbase + '-cluster.2x.png'
                , scaledSize: new google.maps.Size(23, 41)
                , labelOrigin: new google.maps.Point(11,12)
                };

  let url = new URL(location.href);
  let position = url.searchParams.get("position");
  let center;
  if (position)
  {
    position = position.substr(1, position.length - 2);
    center = new google.maps.LatLng(...position.split(","));
  }

  // Initialize the map
  map = new google.maps.Map(container, {
    center: center || { lat: 52.26299576262349, lng: 6.2645892289063365 },
    zoom: 9,
    //disableDoubleClickZoom: true,
    scrollwheel: false,
    clickableIcons: false,
    styles: util.getGoogleMapStyles(),
    mapTypeControl: false,
  });

  if (center)
  {
    // Map bounds are only available after the first 'bounds_changed' event, so we'll wait for that before doing the initial
    // search for the given position
    google.maps.event.addListenerOnce(map, "bounds_changed", event => { searchDealersAroundPosition(map.getCenter(), true, parseInt(url.searchParams.get("dealer"))); });
  }

  // Initialize the dealer info window
  dealerInfoWindow = createDealerInfoWindow();

  // Initialize the search results map control
  let searchResultsDiv = document.createElement("div");
  searchResultsControl = new SearchResultsControl(searchResultsDiv, map);
  searchResultsDiv.index = 1;
  map.controls[google.maps.ControlPosition.LEFT_CENTER].push(searchResultsDiv);

  rpc = new JSONRPC();

  // Initialize the Geocoder for text search
  geocoder = new google.maps.Geocoder();


  let mapMarkerUpdateTimer;
  google.maps.event.addListener(map,'bounds_changed', function(){
    if( !markerdataloaded )
      return;
    clearTimeout(mapMarkerUpdateTimer);
    mapMarkerUpdateTimer = setTimeout(function(){ searchDealersWithinBounds(); },300);
  });

  readCategories();
  $('.p-categories input[type="checkbox"]').change(function(evt) {
    evt.preventDefault(evt);
    onCategoriesFilterChange();
  });

  // Search directly if a query was supplied on the url
  if(getSearchLocation())
    handleSearch();
  else if (!position)
    setTimeout( () => findoverlay.openFindStore(), 1);
});

function getSearchLocation() {
  let search = '';

  let url = new URL(location.href);
  let locationParam = url.searchParams.get("location");
  if (locationParam)
    search = locationParam.replace(/\+/g, ' ');

  if (search == '')
    search = $('#findoverlay-dealer-input').val();

  return search;
}

let requestActive = false;

export async function requestLocation(event, returnResult = false)
{
  let button = null;
  if (event)
  {
    event.preventDefault();
    button = dompack.closest(event.target, ".p-uselocation");
  }
  let is_dealerpage = !!button;

  if (!requestActive)
  {
    requestActive = true;
    if (is_dealerpage)
    {
      $('#use-my-location-loading').addClass('show');
      button.classList.add("p-uselocation--retrieving-location");
    }

    // Get the geolocation
    let position;
    let requeststart = Date.now();
    try
    {
      position = await getGeolocation({ timeout: 10*1000, maximumAge: 60*1000 });
    }
    catch (e) { // Geolocation not allowed
      console.log("Location lookup failed", e);
    }

    if (returnResult)
    {
      requestActive = false;
      return position;
    }

    // $('html').addClass('allowscroll');

    if (position && window.google)
    {
      let pos = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

      let meta = { db_locavailable: true
                 , dn_loclat: Math.round(position.coords.latitude*10)/10
                 , dn_loclng: Math.round(position.coords.longitude*10)/10
                 , dn_loctime: Date.now() - requeststart
                 };
      datalayer.triggerEvent("coopertires:dealer_locationreq", meta);

      let bounds = searchDealersAroundPosition(pos);

      if (!is_dealerpage)
      {
        requestActive = false;
        return bounds; // The tire detail page just asks for the bounds
      }
      map.fitBounds(bounds);
      searchDealersWithinBounds(0, true);
      // scrollToMap();
    }
    else if (is_dealerpage)
    {
      datalayer.triggerEvent("coopertires:dealer_locationreq", { db_locavailable: false
                                                               , dn_loctime: Date.now() - requeststart
                                                               , ds_loctype: 'browser'
                                                               });

      // Show a message that location is not available
      swal({
        title: '',
        text: getTid("coopertires_sites:site.locationunavailable"),
        type: 'error',
      });
    }

    if (is_dealerpage)
    {
      $('#use-my-location-loading').removeClass('show');
      button.classList.remove("p-uselocation--retrieving-location");
    }

    requestActive = false;
  }
}

// Promise wrapper around navigator.geolocation.getCurrentPosition
function getGeolocation(options)
{
  return new Promise((resolve, reject) =>
  {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
}

async function handleSearch(event)
{
  if(event)
    dompack.stop(event);

  let query = getSearchLocation();
  if (!query)
    return;

  let searchstart = Date.now();
  let iszip = '0123456789'.split('').some(_ => query.indexOf(_) != -1);

  // Do a geocoder request for the entered address
  let results = await getGeocoderResult(query);
  let meta = { dn_loctime: Date.now() - searchstart
             , ds_loctype: iszip ? 'zip' : 'city'
             , ds_locquery: iszip ? '' : query
             , dn_locresults: results.length
             , ds_locsource: event ? 'button' : location.href.includes('source=product_detail') ? 'product_detail' : 'onload'
             };

  console.log("geocoder result: in: ", query, "out: ", results);
  if(results.length >= 1)
  {
    //extract town and country from the first result
    let towncomp = results[0].address_components.find(_ => _.types.includes("postal_town"));
    let countrycomp = results[0].address_components.find(_ => _.types.includes("country"));
    if(towncomp && countrycomp)
    {
      meta.ds_loctown = towncomp ? towncomp.short_name : "";
      meta.ds_loccountry = countrycomp ? countrycomp.short_name : "";
    }
    meta.dn_loclat = Math.round(results[0].geometry.location.lat() * 10)/10;
    meta.dn_loclng = Math.round(results[0].geometry.location.lng() * 10)/10;
  }
  datalayer.triggerEvent("coopertires:dealer_locationsearch", meta);

  if (results.length)
    searchDealersAroundPosition(results[0].geometry.location, true, 0);

  scrollToMap(true);
}

function scrollToMap(toresults, overridenode )
{
  $('html').addClass('allowscroll');

  let resultsel = document.getElementById('dealerresults');
  let mapel = document.getElementById('dealer-locator-map');

  let scrollTo;
  if( overridenode )
    scrollTo = $(overridenode).offset().top;
  else
    scrollTo = $(toresults && isMobileLayout() ? resultsel : mapel).offset().top;

  $('html,body').animate({scrollTop: scrollTo - 100}, 750);
}

// Promise wrapper around google.maps.Geocoder.geocode
function getGeocoderResult(address)
{
  let deferred = dompack.createDeferred();

  let geocodeOpts = { address, bounds: map.getBounds() };
  if (whintegration.config.obj.cctld)
    geocodeOpts.region = whintegration.config.obj.cctld;

  geocoder.geocode(geocodeOpts, (results, status) =>
  {
    if (status == google.maps.GeocoderStatus.OK || status == google.maps.GeocoderStatus.ZERO_RESULTS)
    {
      //Remove results not given in displaycountries
      if( whintegration.config.site.displaycountries && whintegration.config.site.displaycountries.length )
      {
        for( let i = results.length - 1; i >= 0; --i )
        {
          for( let c = results[i].address_components.length - 1; c >= 0; --c )
          {
            let rec = results[i].address_components[c];
            if( rec.types.indexOf("country") > -1 && whintegration.config.site.displaycountries.indexOf(rec.short_name) == -1 )
            {
              results.splice(i,1);
              break;
            }
          }
        }
      }

      deferred.resolve(results || []);
    }
    else
      deferred.reject(new Error(status));
  });

  return deferred.promise;
}

function searchDealersAroundPosition(position, is_dealerpage, selectId)
{
  __referencelatlng = position;

  // Calculate a range around the position
  let distance = range * 1000 / Math.sqrt(2);
  let ne = moveLatLng(position, 45, distance);
  let sw = moveLatLng(position, 225, distance);
  let bounds = new google.maps.LatLngBounds(sw, ne);

  if (is_dealerpage)
  {
    // Fit the range bounds in the map and find dealers within the displayed bounds
    map.fitBounds(bounds);
    searchDealersWithinBounds(selectId, true);

    scrollToMap(true);
  }
  return bounds; // The tire detail page just asks for the bounds
}

// Find dealers within the current bounds of the map
async function searchDealersWithinBounds(selectId, updateleftpanel )
{
  if( updateleftpanel )
    __updateleftpanel = updateleftpanel;//remember setting until rpc is finished (case rpc updates canceled)

  let bounds = map.getBounds();
  let param = { sw     : { lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng() }
              , ne     : { lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng() }
              , zoom   : map.getZoom()
              , max    : maxResults
              , cat    : categories
              , center : __updateleftpanel ? __referencelatlng : {}
              };

  //Prevent double submit
  let chk = JSON.stringify(param);
  if( chk == __querycheck )
    return;

  __querycheck = chk;

  if(rpc.activerequest)
  {
    for(var c = 0; c < rpc.requestqueue.length; c++)
      if(rpc.requestqueue[c].request.method == 'SearchDealersWithinBounds')
        rpc.requestqueue[c].cancel();//cancel active rpc request
  }

  let searchstart = Date.now();
  let result = await rpc.async("SearchDealersWithinBounds", param.sw, param.ne, param.zoom, param.max, param.cat, param.center );
  let meta = { dn_dealertime: Date.now() - searchstart
             , dn_dealerresults: 0
             }

  if(result.success)
    meta.dn_dealerresults = result.markers.length;

  datalayer.triggerEvent("coopertires:dealer_dealerresults", meta);

  if (result.success)
  {
    let currentmarkers = [];
    let activeids = [];

    for (let dealer of result.markers)
    {
      activeids.push(dealer.groupid);
      let existingidx = -1;
      for( var i = 0; i < activemarkers.length; ++i )
      {
        if( activemarkers[i].groupid == dealer.groupid )
        {
          existingidx = i;
          break;
        }
      }

      if( existingidx == -1 )
      {
        // Create a marker for the result
        let latlng = new google.maps.LatLng(dealer.lat, dealer.lng);
        if( dealer.count > 1 )
        {
          dealer.marker = new google.maps.Marker({
            position: latlng,
            map,
            animation: markerdataloaded ? null : google.maps.Animation.DROP,
            icon: clusterIcon,
            title: dealer.title,
            label: {color: '#fff', fontSize: '13px', fontWeight: '600', text: dealer.count > 50 ? '+50':''+dealer.count},
            clickable: false
          });
        }
        else
        {
          dealer.marker = new google.maps.Marker({
            position: latlng,
            map,
            animation: markerdataloaded ? null : google.maps.Animation.DROP,
            icon: dealer.clubcooper ? clubCooperIcon : dealer.ambassador ? ambassadorIcon : dealerIcon,
            title: dealer.title,
            clickable: true
          });

          // Register a click handler for this result, showing the info for the selected dealer
          dealer.marker.addListener("click", event => doShowDealerInfoWindow(dealer.id, 'map', !!event.resultClick, dealer));
        }

        currentmarkers.push(dealer);
      }
      else
        currentmarkers.push( activemarkers.splice(existingidx, 1)[0] );
    }

    //Cleanup markers not in results
    for( let i = activemarkers.length - 1; i >= 0; --i )
    {
      activemarkers[i].marker.setMap(null);

      //if has open infowindow, close it
      if( dealerInfoWindow && activemarkers[i].id == dealerInfoWindow.dealerId )
      {
        dealerInfoWindow.dealerId = null;
        dealerInfoWindow.anchorDiv.removeChild(dealerInfoWindow.dealerDiv);
        dealerInfoWindow.dealerDiv = null;
      }
    }

    activemarkers = currentmarkers;

    markerdataloaded = true;

    if( __updateleftpanel )
      searchResultsControl.showResults(result.dealers, selectId);

    __updateleftpanel = false;
  }
  else
  {
    // Clear results
    searchResultsControl.showResults();
  }
}


async function updateDealerInfoWindow( dealerid, source, infowindow )
{
  if(rpc.activerequest)
  {
    for(var c = 0; c < rpc.requestqueue.length; c++)
      if(rpc.requestqueue[c].request.method == 'GetDealer')
        rpc.requestqueue[c].cancel();//cancel active rpc request
  }

  let dealer = null;
  if( dealerid )
  {
    dealer = await rpc.async("GetDealer", dealerid, __referencelatlng);
    if (infowindow && infowindow.dealerDiv)
      dompack.empty(infowindow.dealerDiv);

    if( dealer && dealer.id == infowindow.dealerId )
    {
      datalayer.triggerEvent("coopertires:dealer_click", { dn_dealerid: dealerid
                                                         , ds_dealer: dealer.title
                                                         , ds_dealeraddress: dealer.displayaddress
                                                         , ds_clicksource: source
                                                         , db_dealerpremium: dealer.premium
                                                         });

      // Construct some links to use in the template
      dealer.directions = `https://www.google.com/maps/dir/?api=1&destination=${dealer.lat},${dealer.lng}`;
      dealer.mailto = dealer.email != "" ? `mailto:${dealer.email}` : "";

      dealer.tel = dealer.phone ? "tel:" + dealer.phone : "";

      if( dealer.hasopeninghours )
      { //Check if is open today and get todays openinghours
        let curweekday = new Date().getDay() - 1; //JS Sunday = 0, weekday in result monday = 0;
        let isopentoday = false;
        if( curweekday < 0 )
          curweekday = 6;//sunday
        for( let i = 0; i < dealer.openinghours.length; ++i )
        {
          if( dealer.openinghours[i].weekday == curweekday )
          {
            dealer.openinghours = dealer.openinghours[i].hours;
            isopentoday = true;
            break;
          }
        }

        if( !isopentoday )
          dealer.hasopeninghours = false;
      }

      // Format the distance (convert from meters to miles, add "mile(s)"/"km" suffix)
      dealer.distance = util.formatDistance(dealer.distance, whintegration.config.site.use_imperial);
      infowindow.dealerDiv.appendChild( domtemplate.instantiate(document.getElementById("dealer-info"), dealer).firstChild );

      let imgwrapper = infowindow.dealerDiv.querySelector(".p-dealerinfo__image");
      if( imgwrapper && dealer.image )
        imgwrapper.appendChild(<img src={dealer.image.normal} srcset={dealer.image.normal + " 1x," + dealer.image.retina + " 2x"} alt="" />);

      infowindow.dealerDiv.addEventListener("click", event =>
      {
        let eventnameholder = dompack.closest(event.target, "*[data-cooper-clickevent]");
        if(eventnameholder)
          datalayer.triggerEvent(eventnameholder.dataset.cooperClickevent);
      }, true);


      if( __resultfoldoutnode )
      { //load details for foldout in result (mobile layout)
        if( __resultfoldoutnode.classList.contains("loading") )
        {
          let content = domtemplate.instantiate(document.getElementById("dealer-info-mobile"), dealer).children[0];

          dompack.empty(__resultfoldoutnode);
          __resultfoldoutnode.classList.remove("loading");
          __resultfoldoutnode.appendChild( content );
        }
      }

      //Reposition/center infowindow if visible
      let offset = 0;
      if( infowindow.dealerDiv.clientWidth > 0 )
      {
        let containerpos = dompack.closest(infowindow.dealerDiv, "#google-maps-container").getBoundingClientRect();
        let infopos = infowindow.dealerDiv.getBoundingClientRect();

        let offsetbottom = containerpos.bottom - infopos.bottom;
        let offsettop = infopos.top - containerpos.top - 50;
        offset = (offsetbottom - offsettop) / 2;
        if( offset < -offsettop )
          offset = -offsettop;
      }

      offsetMapCenter(0, -offset, infowindow.position);

      infowindow.dealerDiv.querySelector(".p-dealerinfo__close").addEventListener("click", event =>
      {
        event.preventDefault();
        infowindow.dealerId = null;
        infowindow.anchorDiv.removeChild(infowindow.dealerDiv);
        infowindow.dealerDiv = null;
      });
    }
    else
      dealer = null;
  }

  if( !dealer )
  {
    infowindow.dealerId = null;
    if(infowindow.dealerDiv)
    {
      infowindow.anchorDiv.removeChild(infowindow.dealerDiv);
      infowindow.dealerDiv = null;
    }
  }
}

function offsetMapCenter(offsetx, offsety, latlng)
{
  // offsetx is the distance you want that point to move to the right, in pixels
  // offsety is the distance you want that point to move upwards, in pixels
  // offset can be negative

  if(!latlng)
    latlng = map.getCenter();

  let scale = Math.pow(2, map.getZoom());

  let worldCoordinateCenter = map.getProjection().fromLatLngToPoint(latlng);
  let pixelOffset = new google.maps.Point((offsetx/scale) || 0,(offsety/scale) || 0);

  let worldCoordinateNewCenter = new google.maps.Point(
    worldCoordinateCenter.x - pixelOffset.x,
    worldCoordinateCenter.y + pixelOffset.y
  );

  let newcenter = map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);

  //this.map.panTo(newcenter);
  map.setCenter(newcenter);
}

function onCategoriesFilterChange() {
  readCategories();
  searchDealersWithinBounds(0, true);
}

function readCategories() {
  // collect checked filters
  categories = [];
  $('.p-categories input[type="checkbox"]').each(function() {
    if ($(this).prop('checked') === true)
      categories.push($(this).data('type'));
  });
}

dompack.register(".p-dealerresults-newsearch", node => {
  node.addEventListener("click", () => findoverlay.openFindStore());
});
