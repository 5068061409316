import KeyboardHandler from 'dompack/extra/keyboard';
import * as dompack from "dompack";
import * as encoding from "dompack/types/text";
import './autocompleter2.scss';

function bcrToString(bcr)
{
  return "top:" + bcr.top + ", left: " + bcr.left + ", width:" + bcr.width + ", height:" + bcr.height;
}

/* THIS IS EXPERIMENTAL CODE - CURRENTLY BEING USED FOR:
  - schoolinfo fields, see
    https://utwente.moe.sf.webhare.nl/en/education/summer-school-curiousu/registration/inspireu

*/



/*
  cSearchSuggest
    suggest container is inserted in end of search form
  options: catalog: string , consilio catalog name
           rpc: boolean    , switch when option is selected then plain submit or just fire submit event for rpc


getsuggestions receives the entered words. it should either return a simple array of strings (simple results) or an object to return more complex results (needs definition)

*/
export default class cSearchSuggest
{
  constructor(inputnode, getsuggestions, options)
  {
    this.inputnode = inputnode;
    this.getsuggestions = getsuggestions;
    this.options = { noresultstext: '' //ADDME zou dit een prefill moeten hebben?
                   , minwordlength: 3
                   , triminput: true
                   , ...options
                   };

    new KeyboardHandler(this.inputnode, { "Escape": evt => this.removeSuggestions()
                                        , "ArrowDown": evt => this.openSuggestions()
                                        }, { captureunsafekeys:true
                                           })

    // this.options = JSON.parse(inputnode.getAttribute("data-suggest"));

    // if( !this.options.catalog )
    // {
    //   console.warn("No catalog set");
    //   return;
    // }

    this.formnode = dompack.closest(this.inputnode, "form");

    this.history = [];

    document.body.addEventListener("click", ev =>
    {
      if( !this._items )
        return;

      let chknode = dompack.closest(ev.target, "form");
      if( !chknode || chknode != this.formnode )
        this.removeSuggestions();
    });

    this.words = this.inputnode.value;
    this.inputnode.addEventListener("keyup", ev =>
    {
      if( this._items && ev.keyCode == 40 )
      { //down 40, up 38
        ev.preventDefault();
        this._items.querySelector("li").focus();
      }

      if( this.updatetimer )
        clearTimeout(this.updatetimer);

      if( this._getInputValue() != this.words )
        this.updatetimer = setTimeout( ev => this.openSuggestions(), 200);
    });

    this.inputnode.addEventListener("search", ev => this.removeSuggestions() );//case search clear field
  }

  _getInputValue()
  {
    let inpval = this.inputnode.value;
    if( inpval.trim && this.options.triminput)
      inpval = inpval.trim();
    return inpval;
  }

  openSuggestions()
  {
    this.updateList( this._getInputValue() );
  }

  async updateList( words )
  {
    this.words = words;

    //first check if we have already suggestions for given input
    for( let i = this.history.length - 1; i >= 0 && this.words.length >= this.options.minwordlength; --i)
    {
      if( this.history[i].words == this.words )
      {
        this.updateSuggestions(this.history[i].values);
        return;
      }
    }

    if( this.words != "" && this.words.length >= this.options.minwordlength )
    {
      let results = this.translateSuggestions(await this.getsuggestions(this.words));
      if(results)
        this.updateSuggestions(results.values);
    }
    else if( this._items )
      this.removeSuggestions();
  }

  translateSuggestions(suggestionlist)
  {
    if(Array.isArray(suggestionlist))
      return { values: suggestionlist.map(value => ({value:value})) };
    else
      return suggestionlist;
  }

  updateSuggestions( suggestions )
  {
    this.formnode.classList.add("suggestionsactive");

    this.history.push({"words" : this.words, "values" : suggestions });
    if( this.history.length > 100 ) //limit nr items in history
      this.history.shift();

    dompack.dispatchCustomEvent(this.inputnode, "dompack:experimental--suggestions",
                                    { bubbles: true
                                    , cancelable: false
                                    , detail: { suggestions }
                                    })

    if( !this._items )
    {
      this.listitems = [];
      this._items = dompack.create("ul",{ "className" : "csuggest-values"} );

      this._items.addEventListener("keydown", ev =>
      {
        console.log("EV", ev.keyCode);
        if( ev.keyCode == 38 )
        { // Up
          ev.preventDefault();

          let focusednode = this.inputnode;
          for(let i = this.listitems.length - 1; i >= 0; --i)
          {
            if( document.activeElement == this.listitems[i] )
            {
              if( i > 0 )
                focusednode = this.listitems[i - 1];
              break;
            }
          }
          focusednode.focus();
        }
        else if( ev.keyCode == 40 )
        {// Down
          ev.preventDefault();

          let focusednode = this.inputnode;
          for(let i = 0; i < this.listitems.length; ++i)
          {
            if( document.activeElement == this.listitems[i] )
            {
              if(i < this.listitems.length - 1)
                focusednode = this.listitems[i + 1];
              break;
            }
          }
          focusednode.focus();
        }
        else if( ev.keyCode == 27 ) // Esc
        {
          this.inputnode.focus();
          this.removeSuggestions();
        }
        else if( ev.keyCode == 13 ) // Enter
        {
          let item = dompack.closest( ev.target, "li");
          if( item )
          {
            let val = item.getAttribute("data-value");
            for( let item of suggestions )
            {
              if( item.value == val)
              {
                this._selectSuggestion(item);
                return;
              }
            }
           // this.inputnode.value = val;

            this.removeSuggestions();//remove list

            // if( this.options.rpc ) // trigger Rpc
            //   dompack.dispatchCustomEvent(this.formnode, "submit", { bubbles: false, cancelable: true});
            // else
            //   this.formnode.submit();//basic submit
          }
        }
      });
    }

    dompack.empty(this._items);//first empty container


    if( !suggestions.length )
    {
      if(this.options.noresultstext) //FIXME should keep BEM item classes for noresults and results to prevent cursor/highlights
      {
        let node = dompack.create("li", { className : "noresults", textContent: this.options.noresultstext });
        this._items.appendChild(node);
      }
      else
      {
        this.removeSuggestions();
        return;
      }
    }
    for( let item of suggestions )
    {
      let val = encoding.encodeTextNode(item.value);
      let line = val.replace(this.words, "<span class=\"match\">" + encoding.encodeTextNode(this.words) + "</span>")

      let node = dompack.create("li", { className : "suggestion", "innerHTML" : line } );
      node.setAttribute("tabindex", "0"); //FIXME these probably shouldn't be in the tab ordering. but should they be even focusable?
      node.setAttribute("data-value", item.value);

      node.addEventListener("click", ev => { dompack.stop(ev); this._selectSuggestion(item) });
      this.listitems.push(node);
      this._items.appendChild(node);
    }

    this._openPulldown();
  }

  _selectSuggestion(item)
  {
    this.inputnode.value = item.value;
    this.removeSuggestions();//hide/remove list

    dompack.focus(this.inputnode);
    this.inputnode.selectionStart = this.inputnode.value.length;
    this.inputnode.selectionEnd = this.inputnode.value.length;

    dompack.dispatchCustomEvent(this.inputnode, "dompack:experimental--autosuggested",
                                    { bubbles: true
                                    , cancelable: false
                                    , detail: { item: item }
                                    })
  }

  removeSuggestions()
  {
    this.formnode.classList.remove("suggestionsactive");

    if( !this._items )
      return;
    dompack.remove(this._items);
    this._items = null;

    dompack.dispatchCustomEvent(this.inputnode, "dompack:experimental--closesuggestions",
                                    { bubbles: true
                                    , cancelable: false
                                    })
  }


  //IMPORT NOTE: : this is mostly a copy from dompack pulldown. inputnode == _newnode
  _isOpen()
  {
    return !!(this._items && this._items.parentNode);
  }
  _openPulldown()
  {
    if(this._isOpen())
      return;

    //fix the width, as we're removing our contents so they won't keep us at the proper width
    let pulldowncoords = this.inputnode.getBoundingClientRect();
    let itemscoords = this._items.getBoundingClientRect();
    if(itemscoords.height == 0) //not in DOM?
    {
      document.body.appendChild(this._items);
      itemscoords = this._items.getBoundingClientRect();
    }

    this._openbottom = true;
    let bottomroom = window.innerHeight - pulldowncoords.bottom;
    let toproom = pulldowncoords.top;

    if(itemscoords.bottom > window.innerHeight) //the pulldown won't fit below us
    {
      //if we have at least half the room on the bottom as we do on top, still prefer bottom
      //this._openbottom = bottomroom >= toproom / 2; //TODO configurable policy?
      this._openbottom = bottomroom >= 120; //for COOPER, generally prefer 'below'
    }

    //if we Math.ceil the width, we risk triggering a word wrapping on ourselves
    this.inputnode.style.minWidth = pulldowncoords.width + 'px';
    this._items.style.minWidth = pulldowncoords.width + 'px';
    if(this._fixitemswidth) //IMPORT NOTE: Not possible to set this here, but defaultsto false in pulldown.es
      this._items.style.width = this._items.style.minWidth;
    // this.inputnode.classList.add(this._pulldownclass + '--open');
    // this._items.classList.add(this._pulldownclass + '--openvalues');

    if(this._openbottom)
    {
      this._items.style.maxHeight = bottomroom + 'px';
    }
    else
    {
      this._items.style.maxHeight = toproom + 'px';
    }

    //set up capturing handlers to kill our pulldowns asap when something else is clicked
    // if(!this._boundGlobalMouseDown)
    //   this._boundGlobalMouseDown = evt => this._globalMouseDown(evt);
    // if(!dompack.debugflags.meo)
    // {
    //   window.addEventListener("mousedown",  this._boundGlobalMouseDown, true);
    //   window.addEventListener("touchstart", this._boundGlobalMouseDown, true);
    // }
    //we need to join the body, because even with fixed ignoring overflows, we can still be clipped by z-index
    document.body.appendChild(this._items);

    this._lastpulldowncoords = {};
    this._positionPulldown();

   // this._items.style.left = Math.ceil(pulldowncoords.left) + 'px';
  }
  _positionPulldown()
  {
     if(!this._isOpen())
       return;

    let input_bcr = this.inputnode.getBoundingClientRect();
    let items_bcr = this._items.getBoundingClientRect();
    let scrollx = (window.scrollX || document.documentElement.scrollLeft || 0);
    let scrolly = (window.scrollY || document.documentElement.scrollTop || 0);

    this._items.style.left = Math.floor(input_bcr.left + scrollx)  + 'px';
    if(this._openbottom)
      this._items.style.top = Math.floor(input_bcr.bottom + scrolly) + 'px';
    else
      this._items.style.top = Math.floor(input_bcr.top - items_bcr.height + scrolly) + 'px';

    requestAnimationFrame(()=>this._positionPulldown());
  }

  _positionPulldown_Absolute()
  {
    let bcr = this.inputnode.getBoundingClientRect();
    console.log("ABS",JSON.stringify({scrollX:window.scrollX,left:bcr.left,toset:bcr.left + window.scrollX}));
    this._items.style.left = Math.floor(bcr.left + window.scrollX) + 'px';

    if(this._openbottom)
    {
      //this._items.style.top = Math.ceil(pulldowncoords.bottom) + 'px';
      this._items.style.top = Math.floor(bcr.bottom + window.scrollY) + 'px';
      this._items.style.bottom = '';
    }
    else
    {
      this._items.style.top = '';
      this._items.style.bottom = Math.floor(window.innerHeight - bcr.top + document.body.scrollHeight) + 'px';
    }

  }
  _positionPulldown_FixedStrategy()
  {
     if(!this._isOpen())
       return;

    //we need to copy it, getBCR is a weird object
    let bcr = this.inputnode.getBoundingClientRect();

    if(this._lastpulldowncoords.top != bcr.top
       || this._lastpulldowncoords.left != bcr.left
       || this._lastpulldowncoords.bottom != bcr.bottom)
    { //we moved
      this._lastpulldowncoords = bcr;

      if(this._openbottom)
      {
        //this._items.style.top = Math.ceil(pulldowncoords.bottom) + 'px';
        this._items.style.top = Math.floor(bcr.bottom) + 'px';
        this._items.style.bottom = '';
      }
      else
      {
        this._items.style.top = '';
        this._items.style.bottom = Math.floor(window.innerHeight - bcr.top) + 'px';
      }
    }
    requestAnimationFrame(()=>this._positionPulldown());
  }
}
