import './footer.scss';

// WebHare
import * as dompack from 'dompack';

// internal
import * as util from '../shared/js/utilities';

dompack.onDomReady(() => {
});
