// Auto-generated language file from /opt/wh/whtree/modules/tollium/web/ui/components/richeditor/richeditor.lang.json
var registerTexts = require("@mod-tollium/js/gettid").registerTexts;
registerTexts("tollium","en",{"components":{"rte":{"inspect":"Inspect","properties":"Properties","table_addcolumn_after":"Add Column to the Right","table_addcolumn_before":"Add Column to the Left","table_addpara_after":"Add Paragraph after Table","table_addpara_before":"Add Paragraph above table","table_addrow_after":"Add Row Below","table_addrow_before":"Add Row Above","table_deletecolumn":"Remove Column","table_deleterow":"Remove Row","table_mergedown":"Merge With Cells Below","table_mergeright":"Merge With Cells to the Right","table_splitcols":"Split Columns","table_splitrows":"Split Rows","toomanyplaintextbytes":["Field '",1,"' contains more text than recommended (",2," bytes, ",3," recommended)."],"toomanyplaintextbytes_error":["Field '",1,"' contains more text than allowed (",2," bytes, ",3," allowed)."],"toomanyplaintextcharacters":["Field '",1,"' contains more text than recommended (",2," characters, ",3," recommended)."],"toomanyplaintextcharacters_error":["Field '",1,"' contains more text than allowed (",2," characters, ",3," allowed)."]}}});
registerTexts("tollium","es",{"components":{"rte":{"inspect":"Inspect","properties":"Properties","table_addcolumn_after":"Add Column to the Right","table_addcolumn_before":"Add Column to the Left","table_addpara_after":"Add Paragraph after Table","table_addpara_before":"Add Paragraph above table","table_addrow_after":"Add Row Below","table_addrow_before":"Add Row Above","table_deletecolumn":"Remove Column","table_deleterow":"Remove Row","table_mergedown":"Merge With Cells Below","table_mergeright":"Merge With Cells to the Right","table_splitcols":"Split Columns","table_splitrows":"Split Rows","toomanyplaintextbytes":["Field '",1,"' contains more text than recommended (",2," bytes, ",3," recommended)."],"toomanyplaintextbytes_error":["Field '",1,"' contains more text than allowed (",2," bytes, ",3," allowed)."],"toomanyplaintextcharacters":["Field '",1,"' contains more text than recommended (",2," characters, ",3," recommended)."],"toomanyplaintextcharacters_error":["Field '",1,"' contains more text than allowed (",2," characters, ",3," allowed)."]}}});
registerTexts("tollium","de",{"components":{"rte":{"inspect":"Inspect","properties":"Properties","table_addcolumn_after":"Add Column to the Right","table_addcolumn_before":"Add Column to the Left","table_addpara_after":"Add Paragraph after Table","table_addpara_before":"Add Paragraph above table","table_addrow_after":"Add Row Below","table_addrow_before":"Add Row Above","table_deletecolumn":"Remove Column","table_deleterow":"Remove Row","table_mergedown":"Merge With Cells Below","table_mergeright":"Merge With Cells to the Right","table_splitcols":"Split Columns","table_splitrows":"Split Rows","toomanyplaintextbytes":["Field '",1,"' contains more text than recommended (",2," bytes, ",3," recommended)."],"toomanyplaintextbytes_error":["Field '",1,"' contains more text than allowed (",2," bytes, ",3," allowed)."],"toomanyplaintextcharacters":["Field '",1,"' contains more text than recommended (",2," characters, ",3," recommended)."],"toomanyplaintextcharacters_error":["Field '",1,"' contains more text than allowed (",2," characters, ",3," allowed)."]}}});
registerTexts("tollium","fr",{"components":{"rte":{"inspect":"Inspect","properties":"Properties","table_addcolumn_after":"Add Column to the Right","table_addcolumn_before":"Add Column to the Left","table_addpara_after":"Add Paragraph after Table","table_addpara_before":"Add Paragraph above table","table_addrow_after":"Add Row Below","table_addrow_before":"Add Row Above","table_deletecolumn":"Remove Column","table_deleterow":"Remove Row","table_mergedown":"Merge With Cells Below","table_mergeright":"Merge With Cells to the Right","table_splitcols":"Split Columns","table_splitrows":"Split Rows","toomanyplaintextbytes":["Field '",1,"' contains more text than recommended (",2," bytes, ",3," recommended)."],"toomanyplaintextbytes_error":["Field '",1,"' contains more text than allowed (",2," bytes, ",3," allowed)."],"toomanyplaintextcharacters":["Field '",1,"' contains more text than recommended (",2," characters, ",3," recommended)."],"toomanyplaintextcharacters_error":["Field '",1,"' contains more text than allowed (",2," characters, ",3," allowed)."]}}});
registerTexts("tollium","it",{"components":{"rte":{"inspect":"Inspect","properties":"Properties","table_addcolumn_after":"Add Column to the Right","table_addcolumn_before":"Add Column to the Left","table_addpara_after":"Add Paragraph after Table","table_addpara_before":"Add Paragraph above table","table_addrow_after":"Add Row Below","table_addrow_before":"Add Row Above","table_deletecolumn":"Remove Column","table_deleterow":"Remove Row","table_mergedown":"Merge With Cells Below","table_mergeright":"Merge With Cells to the Right","table_splitcols":"Split Columns","table_splitrows":"Split Rows","toomanyplaintextbytes":["Field '",1,"' contains more text than recommended (",2," bytes, ",3," recommended)."],"toomanyplaintextbytes_error":["Field '",1,"' contains more text than allowed (",2," bytes, ",3," allowed)."],"toomanyplaintextcharacters":["Field '",1,"' contains more text than recommended (",2," characters, ",3," recommended)."],"toomanyplaintextcharacters_error":["Field '",1,"' contains more text than allowed (",2," characters, ",3," allowed)."]}}});
registerTexts("tollium","ar",{"components":{"rte":{"inspect":"Inspect","properties":"Properties","table_addcolumn_after":"Add Column to the Right","table_addcolumn_before":"Add Column to the Left","table_addpara_after":"Add Paragraph after Table","table_addpara_before":"Add Paragraph above table","table_addrow_after":"Add Row Below","table_addrow_before":"Add Row Above","table_deletecolumn":"Remove Column","table_deleterow":"Remove Row","table_mergedown":"Merge With Cells Below","table_mergeright":"Merge With Cells to the Right","table_splitcols":"Split Columns","table_splitrows":"Split Rows","toomanyplaintextbytes":["Field '",1,"' contains more text than recommended (",2," bytes, ",3," recommended)."],"toomanyplaintextbytes_error":["Field '",1,"' contains more text than allowed (",2," bytes, ",3," allowed)."],"toomanyplaintextcharacters":["Field '",1,"' contains more text than recommended (",2," characters, ",3," recommended)."],"toomanyplaintextcharacters_error":["Field '",1,"' contains more text than allowed (",2," characters, ",3," allowed)."]}}});
registerTexts("tollium","nl",{"components":{"rte":{"inspect":"Inspecteer","properties":"Eigenschappen","table_addcolumn_after":"Kolom toevoegen aan de rechterkant","table_addcolumn_before":"Kolom toevoegen aan de linkerkant","table_addpara_after":"Voeg paragraaf toe na tabel","table_addpara_before":"Voeg paragraaf toe boven tabel","table_addrow_after":"Rij toevoegen hieronder","table_addrow_before":"Rij toevoegen hierboven","table_deletecolumn":"Kolom verwijderen","table_deleterow":"Rij verwijderen","table_mergedown":"Samenvoegen met onderste cellen","table_mergeright":"Samenvoegen met cellen aan de rechterkant","table_splitcols":"Kolommen splitsen","table_splitrows":"Rijen splitsen","toomanyplaintextbytes":["Veld '",1,"' bevat meer tekst dan aangeraden (",2," bytes, ",3," aangeraden)."],"toomanyplaintextbytes_error":["Veld '",1,"' bevat meer tekst dan toegestaan (",2," bytes, ",3," toegestaan)."],"toomanyplaintextcharacters":["Veld '",1,"' bevat meer tekst dan aangeraden (",2," karakters, ",3," aangeraden)."],"toomanyplaintextcharacters_error":["Veld '",1,"' bevat meer tekst dan toegestaan (",2," karakters, ",3," toegestaan)."]}}});
// Adding dependency: /opt/wh/whtree/modules/tollium/language/default.xml
// Adding dependency: /opt/wh/whtree/modules/tollium/language/default.xml
// Adding dependency: /opt/wh/whtree/modules/tollium/language/default.xml
// Adding dependency: /opt/wh/whtree/modules/tollium/language/default.xml
// Adding dependency: /opt/wh/whtree/modules/tollium/language/default.xml
// Adding dependency: /opt/wh/whtree/modules/tollium/language/default.xml
// Adding dependency: /opt/wh/whtree/modules/tollium/language/nl.xml
// Adding dependency: /opt/wh/whtree/modules/tollium/language/default.xml
