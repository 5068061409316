//import './hotspots.scss';

import * as dompack from 'dompack';

import $ from 'jquery';

dompack.onDomReady(() => {
  $('.tirefeatures__hotspot').click(function(evt) {
    evt.preventDefault();

    let $parent = $(this).closest('.tirefeatures__hotspotcontainer');

    if ($parent.hasClass('show')) { // clicking the item that's already open
      $parent.removeClass('show');
      return;
    }

    // clicking an other item, hide all first
    $('.tirefeatures__hotspotcontainer').removeClass('show');

    // and show the clicked one
    $parent.addClass('show');
  });

  $('.tirefeatures__hotspotinfowindow-close').click(function(evt) {
    evt.preventDefault();

    // just close them all
    $('.tirefeatures__hotspotcontainer').removeClass('show');
  });

  // move any info windows that fall out of the viewport back into the viewport
  $('.tirefeatures__hotspotinfowindow').each(function() {
    let bcr = $(this).get(0).getBoundingClientRect();
    let windowWidth = $('body').width();
    if (bcr.x + bcr.width > windowWidth) {
      let movePos = -1 * (bcr.x + bcr.width - windowWidth + 20 /* bit extra */);
      $(this).css('transform', 'translateX(' + movePos + 'px)');
    }
  });

  // if (dompack.debugflags['debug'])
  //   $('.tirefeatures__hotspot').last().trigger('click');
});
