// WebHare
import { promiseScript } from 'dompack/extra/preload';
import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import getTid from "@mod-tollium/js/gettid";

export let googleMaps = awaitLoadGoogleMaps();

// vendor
import $ from 'jquery';
require('../../web/jquery-ui/jquery-ui.min.js');

export function getScrollY() {
  return window.scrollY || window.pageYOffset || document.body.scrollTop;
}

export function setExternalLinksTarget() {
  for (let node of document.querySelectorAll('a')) {
    if (node.getAttribute('target')) // target already set
      continue;

    let isPDF = node.href.toLowerCase().includes('.pdf');
    let isHttpProtocol = node.protocol === 'http:' || node.protocol === 'https:';
    //during migration, whintegration.config.site.samesiteurls  may not yet be set( needs republish) - 2018-08-27
    let isInCurrentDomain = !whintegration.config.site.samesiteurls || whintegration.config.site.samesiteurls.some(prefix => node.href.startsWith(prefix));

    if (isPDF || (isHttpProtocol && !isInCurrentDomain))
      node.setAttribute('target', '_blank');
  }
}

export function testBreakPoint(testBp) {
  let screenWidth = $(window).width();

  if (testBp == 'sm')
    return screenWidth <= 576;
  else if (testBp == 'md')
    return screenWidth <= 768;
  else if (testBp == 'lg')
    return screenWidth <= 1034;

  console.error('unknown breakpoint', testBp);

  return false;
}

export function updateSelectOptions(select, options, keepvalue = true)
{
  let value = select.value;
  dompack.empty(select);
  dompack.append(select, ...options.map(option => <option value={option.value} selected={keepvalue && option.value === value}>{option.title}</option>));
}

export function getGoogleMapStyles() {
  return [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}];
}

export function formatDistance(distance, use_imperial)
{
  // Convert from meters to km
  distance = distance / 1000;

  if (use_imperial)
  {
    // Convert from km to miles
    distance = "" + (distance / 1.609344);
  }
  else
    distance = "" + distance;

  // Leave at least two significant digits and at most one decimal
  let parts = distance.split(".");
  if (parts.length == 1 || parts[0].length > 1 || parts[1].substr(0, 1) == "0")
    return `${parts[0]} ${use_imperial ? `mile${parts[0] == "1" ? "" : "s"}` : "km"}`;
  return `${parts[0]}.${parts[1].substr(0, 1)} ${use_imperial ? `mile${parts[0] == "1" ? "" : "s"}` : "km"}`;
}

function prepareIconSelectMenu() {
  $.widget('custom.iconselectmenu', $.ui.selectmenu, {
    _renderItem: function(ul, item) {
      let li = $('<li>');

      if (item.disabled)
        li.addClass('ui-state-disabled');

      let wrapper;
      if ($(item.element).data('istiresize')) {
        let size = $(item.element).data('size');
        let options = $(item.element).data('options');
        let wrapperInner = $(`<span class="select-tiresize--size">${size}</span><span class="select-tiresize--options">${options}</span>`);
        wrapper = $('<div>', { html: wrapperInner });
      } else if ($(item.element).data('isspeedindex')) {
        let value = $(item.element).data('value');
        let speed = $(item.element).data('speed');
        let wrapperInner = $(`<span class="select-speedindex--value">${value}</span><span class="select-speedindex--speed">${speed}</span>`);
        wrapper = $('<div>', { html: wrapperInner });
      } else {
        wrapper = $('<div>', { text: item.label });
      }

      return li.append(wrapper).appendTo(ul);
    }
  });
}


export function RefreshCustomStylePulldown(node)
{
  $(node).selectmenu( "refresh" );
}


export function applyCustomStylePulldown($selector, customStyling, onSelect) {
  if(!$selector[0])
    return;

  if (customStyling)
    prepareIconSelectMenu();

  let options = {};

  if (onSelect) {
    options.select = onSelect;
     $selector[0].propOnSelectHandler = onSelect; //hack for testframework. people should just use proper HTML5 event hadling...
  }

  if (customStyling)
    $selector.iconselectmenu(options);
  else
    $selector.selectmenu(options);
}

export function isAnyPartOfElementInViewport(el) {
  const rect = el.getBoundingClientRect();
  const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
  const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

  const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
  const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

  return (vertInView && horInView);
}

export function log(...message) {
  if (dompack.debugflags['debug'])
    console.log(...message);
}

export function getDefaultPaginationSettings() {
  let settings = {
    prevText: getTid("coopertires_sites:site.previous"),
    nextText: getTid("coopertires_sites:site.next"),
    ellipsePageSet: false,
    edges: 1,
    cssStyle: '',
  };

  let screenWidth = $(window).width();

  if (screenWidth < 700)
    settings.edges = 0;

  if (screenWidth < 500)
    settings.displayedPages = 3;

  if (screenWidth < 350)
    settings.displayedPages = 2;

  return settings;
}

export function isAvonSite() {
  return whintegration.config.site.isavon === true;
}

export function setupImageTextBlocks() {
  if (!whintegration.config.site.isavon)
    return;

  $('.imagetextblock__bgimage').each(function() {
    let $container = $(this).closest('.imagetextblock__image-container');
    let $textContainer = $container.find('.imagetextblock__textcontainer');

    if ($textContainer.length == 0) { // no text container, just take up all space
      $(this).css('height', '100%');
      return;
    }

    let thisHeight = $container.outerHeight();

    if ($textContainer.length > 0)
      thisHeight = thisHeight - $textContainer.outerHeight();

    $(this).height(thisHeight);
  });
}

export function isInternetExplorer() {
  let ua = window.navigator.userAgent;
  let isInternetExplorer = ua.indexOf("MSIE ") > 0
                           || !!ua.match(/Trident.*rv\:11\./)
                           || dompack.debugflags['isie'] === true;

  return isInternetExplorer === true;
}

export async function awaitLoadGoogleMaps() {
  //TODO remove hardcoded key
  await promiseScript(`https://maps.googleapis.com/maps/api/js?key=AIzaSyA7yf-p59Y9lf3riQshZAyBK-NjlErpZ34&libraries=places&language=${document.documentElement.lang}`);
}
