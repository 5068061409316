import './socialmedia.scss';

import * as dompack from 'dompack';
import $ from 'jquery';
import getTid from "@mod-tollium/js/gettid";

dompack.onDomReady(() => {
  let $hoverText = $('.socialmedia__hovertext');

  $('.socialmedia__network').hover(function() {
    let type = $(this).data('type');
    let text = getTid('coopertires_sites:site.socialmedia.hovertext', type);
    text = `To ${type}`; //FIXME: need translations, for now force EN

    $hoverText.text(text);
  });
});
