/* global Swiper, WebKitCSSMatrix */

import './sponsorship2.scss';

import * as dompack from 'dompack';
import * as util from '../../shared/js/utilities';
import $ from 'jquery';
import { TweenLite, TimelineLite } from 'gsap';

import LineupSlideshow from '../../lineupslideshow/lineupslideshow';

let cardWidth = 0; // assuming all cards have the same width
let cardGutter = 30;

class Sponsorship {
  constructor($container) {
    this.$container = $container;

    this.cardSpaceX = cardWidth + cardGutter;
    this.currentCard = 0; // index

    this.$cardContainers = this.$container.find('.emb-sponsorship2__cards');
    this.$nextButton = this.$container.find('.swiper-button-next');
    this.$prevButton = this.$container.find('.swiper-button-prev');
    this.progressBarContainer = this.$container.find('.emb-sponsorship2__progressbarcontainer');
    this.progressBar = this.$container.find('.emb-sponsorship2__progressbar');
    this.$tabs = this.$container.find('.lineup__tab');

    $container.find('.swiper-button-next').click((evt) => this.onNext(evt));
    $container.find('.swiper-button-prev').click((evt) => this.onPrev(evt));
    this.$tabs.click((evt) => this.setTab(evt));

    this.$mobileSlideshow = new LineupSlideshow(this.$container.find('.emb-sponsorship2__mobileslideshow'));
    this.$mobileSlideshow.setup(this.$container.find('.lineupslideshow').first());

    let $totalNum = this.$container.find('.emb-sponsorship2__totalnum');

    // set proper position for cards
    this.$cardContainers.each(function(idx, el) {
      let $cards = $(this).find('.e-card');

      $cards.each(function(idx, el) {
        let translateX = idx * cardWidth + idx * cardGutter;

        if (document.documentElement.dir == 'rtl')
          translateX = -1 * translateX;

        $(this).css('transform', `translateX(${translateX}px)`).get(0).setAttribute('initial-transform', translateX);
      });

      if (idx == 0)
        $totalNum.text('/' + $cards.length.toString().padStart(2, '0'));
    });

    this.updateNavButtonsState();
    this.updateProgressBar();
  }

  onNext(evt) {
    evt.preventDefault();
    this.handleNavigation(true);
  }

  onPrev(evt) {
    evt.preventDefault();
    this.handleNavigation(false);
  }

  calculateCardPosition(cardIndex) {
    let pos = cardIndex * this.cardSpaceX - (this.currentCard * this.cardSpaceX);
    if (document.documentElement.dir == 'rtl')
      return -1 * pos;

    return pos;
  }

  handleNavigation(next = false) {
    let $currentCards = this.getCurrentCards();

    if (next) {
      // move the current cards to their new position
      TweenLite.to($currentCards, .5, { x: (i) => this.calculateCardPosition(i) });

      // fade out the first card
      TweenLite.to($currentCards.eq(this.currentCard), .2, { opacity: 0, 'pointer-events': 'none' });

      this.currentCard += 1;
    } else {
      let tl = new TimelineLite();

      // move the current cards to their new position
      tl.to($currentCards, .5, { x: (i) => this.calculateCardPosition(i) })
      // fade in the first card
      .to($currentCards.eq(this.currentCard - 1), .5, { opacity: 1, 'pointer-events': 'auto', delay: -0.1 });

      this.currentCard -= 1;
    }

    // animate the pagenum (fadeout current number & move to bottom, fadein new number & move in from top; reverse for prev)
    let $currentNum = this.$container.find('.emb-sponsorship2__pagenum');
    let nextNumber = next ? this.currentCard + 1 : this.currentCard + 1;
    let $nextNum = $(`<div class="emb-sponsorship2__pagenum">${nextNumber.toString().padStart(2, '0')}</div>`);
    $nextNum.css({ opacity: 0, transform: `translateY(${(next ? -10 : 10)}px)` });
    this.$container.find('.emb-sponsorship2__pagenumcontainer').append($nextNum);

    let tl = new TimelineLite({ onComplete: () => {
      $currentNum.remove();
    }});
    tl.to($currentNum, .3, { opacity: 0, y: next ? 10 : -10 })
      .to($nextNum, .3, { opacity: 1, y: 0 });

    this.updateProgressBar();
    this.updateNavButtonsState();
  }

  updateNavButtonsState() {
    let $currentCards = this.getCurrentCards();
    this.$nextButton.toggleClass('swiper-button-disabled', this.currentCard + 1 >= $currentCards.length);
    this.$prevButton.toggleClass('swiper-button-disabled', this.currentCard == 0);
  }

  updateProgressBar(numItems = -1) {
    if (numItems == -1)
      numItems = this.getCurrentCards().length;

    let setWidth = (this.currentCard + 1) / numItems;
    this.progressBar.css('width', `${100 * setWidth}%`);
  }

  getCurrentCategory() {
    return this.$container.find('.emb-sponsorship2__cards.enabled');
  }

  getCurrentCards() {
    return this.getCurrentCategory().find('.e-card');
  }

  getVisibleCards() {
    // gather cards in viewport
    let $currentCards = [];
    this.getCurrentCards().each(function() {
      if (util.isAnyPartOfElementInViewport($(this).get(0)))
        $currentCards.push($(this));
    });

    return $currentCards;
  }

  setTab(evt) {
    evt.preventDefault();

    let $tab = $(evt.target);
    if ($tab.hasClass('selected'))
      return;

    let tabIndex = parseInt($tab.data('tab'));
    this.$tabs.removeClass('selected').eq(tabIndex).addClass('selected');
    $tab.closest('.emb-sponsorship2').get(0).removeAttribute('data-aos');

    // check whether we need to animate for desktop or mobile
    let $wrapper = this.$container.find('.emb-sponsorship2__wrapper').first();
    let isDesktop = $wrapper.is(':visible');
    if (isDesktop) {
      // gather cards in viewport
      let $visibleCards = this.getVisibleCards();

      let $enableCategory = this.$container.find('.emb-sponsorship2__cards').eq(tabIndex);
      let $enableCards = $enableCategory.find('.e-card');
      $enableCards.css('opacity', 0);

      // reset cards to their initial position
      $enableCards.each(function(idx, el) {
        let initialPos = parseInt($(this).get(0).getAttribute('initial-transform'));
        TweenLite.to($(this), 0, { x: initialPos });
      });

      let tl = new TimelineLite();
      tl.staggerTo($visibleCards, .4, { top: -40, opacity: 0 }, 0.05) // hide cards
        .to(this.getCurrentCategory(), 0, {css:{className:'-=enabled'}}) // disable current category
        .to($enableCategory, 0, {css:{className:'+=enabled'}}) // enable category
        .to($enableCards, .05, { top: -40 }) // position cards
        .staggerTo($enableCards, .4, { opacity: 1, top: 0 }, 0.05); // fade in cards

      this.currentCard = 0;
      this.updateNavButtonsState();
      this.updateProgressBar($enableCards.length);
      this.$container.find('.emb-sponsorship2__pagenum').text('01');
      this.$container.find('.emb-sponsorship2__totalnum').text('/' + $enableCards.length.toString().padStart(2, '0'));
    } else {
      let $enableSlideshow = this.$container.find('.lineupslideshow').eq(tabIndex);
      this.$mobileSlideshow.enable($enableSlideshow);
    }
  }
}

dompack.onDomReady(() => {
  if ($('.emb-sponsorship2').length == 0)
    return;

  cardWidth = $('.emb-sponsorship2 .e-card').first().get(0).getBoundingClientRect().width;

  $('.emb-sponsorship2').each(function() {
    new Sponsorship($(this));
  });
});

