/* global google */

import './findoverlay.scss';

import * as whintegration from '@mod-system/js/wh/integration';
import * as dompack from 'dompack';
import * as ModelSearch from "@mod-coopertires_sites/js/modelsearch.es";
import * as CarDBService from "@mod-coopertires_sites/js/cardbservice.rpc.json";
import cSearchSuggest from '@mod-coopertires_sites/webdesigns/cooper/components/autocomplete2';
import getTid from "@mod-tollium/js/gettid";
import { requestLocation } from '../pages/dealer_locator/dealer_locator';
import { updateSelectOptions, applyCustomStylePulldown, googleMaps } from "../shared/js/utilities";
import { navigateTo } from '../shared/international';
import * as datalayer from '../shared/js/datalayer';

import $ from 'jquery';
import swal from 'sweetalert2';
import * as examplecontainer from './examplecontainer';

let resultcache = {};

export function handleLocationLookupFormSubmit(evt)
{
  let form = evt.target.closest('form');
  if(form)
  {
    let autocompletecontrol = form.querySelector(".googlemaps--autocomplete");
    if(autocompletecontrol)
    {
      let place = dompack.qS('#findoverlay-dealer-input').propAutocomplete.getPlace();
      if(place && place.geometry)
      {
        dompack.stop(evt);
        location.href = form.action + '?location='+ encodeURIComponent(form.elements.location.value) + "&position=" + encodeURIComponent(place.geometry.location.toString());
      }
    }
  }
}

async function setupAutoComplete(el)
{
  await googleMaps;

  google.maps.event.addDomListener(window, 'load', function()
  {
    const autocomplete = new google.maps.places.Autocomplete(el, {types: ['geocode']});
    el.propAutocomplete = autocomplete;
    autocomplete.setFields(['name','geometry']);

    let countries;
    if (whintegration.config.site.markets && whintegration.config.site.markets.length)
    {
      if(whintegration.config.site.markets.length <= 5) //google places only allows up to 5 countries in the filter
        countries = whintegration.config.site.markets;
    }
    else
    {
      countries = [whintegration.config.site.country];
    }

    if(countries)
    {
      if(countries.length < 5 && countries.includes("FR"))
        countries.push("CH"); //If France, also search Switzerland - https://gitlab.webhare.com/coopertires/coopertires/-/issues/515#note_95855
      autocomplete.setComponentRestrictions({'country': countries });
    }

    console.log("configured places autocomplete ",el,countries);
  });
}

dompack.register('.googlemaps--autocomplete', el => setupAutoComplete(el));

class FindOverlay
{
  constructor() {
    this.updating_selects = false;
    this.updating_motorsport_selects = false;
  }

  initialize()
  {
    //store the current link to the tire overviews so we can easily update it
    const tireoverviewlink = dompack.qS(".findoverlay__tireoverviewlink");
    this.tireoverviewlink = tireoverviewlink ? new URL(tireoverviewlink.href) : null;

    this.searchinfo = whintegration.config.obj.searchinfo;

    this.$dealerSearchInput = $('#findoverlay-dealer-input');

    applyCustomStylePulldown($('#findoverlay__application'), false, (evt) => { this.updateFilters(evt); });
    applyCustomStylePulldown($('.findoverlay select[name="width"]'), false, (evt) => { this.updateFilters(evt); });
    applyCustomStylePulldown($('.findoverlay select[name="aspectratio"]'), false, (evt) => { this.updateFilters(evt); });
    applyCustomStylePulldown($('.findoverlay select[name="rimsize"]'), false, (evt) => { this.updateFilters(evt); });
    applyCustomStylePulldown($('.findoverlay select[name="construction"]'), false, (evt) => { this.updateMotorsportFilters(evt); });
    applyCustomStylePulldown($('.findoverlay select[name="rimsize_motorsport"]'), false, (evt) => { this.updateMotorsportFilters(evt); });
    applyCustomStylePulldown($('.findoverlay select[name="tyresize"]'), false, (evt) => { this.updateMotorsportFilters(evt); });

    let $vehicleLabel = $('.findoverlay__inputs--vehicle .wh-form__label');
    $vehicleLabel.html($vehicleLabel.text().replace(/-/g, `<span class="label-divider">•</span>`));

    // hide/show floating label on focus/blur
    $('.findoverlay__inputs--vehicle input, .findoverlay__inputs--location input').focus(function() {
      $(this).closest('.wh-form__fieldgroup').find('.wh-form__label').hide();
      document.documentElement.classList.add('findoverlay-search-enabled');
    }).blur(function() {
      if ($(this).val() == "")
        $(this).closest('.wh-form__fieldgroup').find('.wh-form__label').show();
    });

    // enable/disable search button on input / no input
    $('.findoverlay__inputs--location input').on('input', function() {
      $('.findoverlay__searchbutton--location').attr('disabled', $(this).val() === '' ? 'disabled' : null);
    });

    // if 'location' in URL, put it in the dealer search input
    let url = new URL(location.href);
    let locationParam = url.searchParams.get("location");
    if (locationParam) {
      this.setDealearSearchInput(locationParam.replace(/\+/g, ' '));
      // this.$dealerSearchInput.val(locationParam.replace(/\+/g, ' ')).closest('.wh-form__fieldgroup').find('.wh-form__label').hide();
      // $('.findoverlay__searchbutton--location').attr('disabled', null);
    }

    // misc event listeners
    dompack.qS('#findoverlay-use-my-location').addEventListener('click', evt => this.useMyLocation(evt));
    $('.header__bottom-bar-main-link[data-tag="find_tyres"], .js-findoverlay-find-tires-open').click(evt => this.onFindTiresClick(evt));
    $('.header__bottom-bar-main-link[data-tag="find_store"], .js-findoverlay-find-store-open').click(evt => this.onFindStoreClick(evt));
    $('.js-findoverlay-find-tires-toggle').click(evt => this.onToggleFindTiresOverlay(evt));
    $('.js-findoverlay-find-store-toggle').click(evt => this.onToggleFindStoreOverlay(evt));
    dompack.qS('.findoverlay__bgoverlay').addEventListener('click', evt => this.closeOverlays(evt));
    $('.js-findoverlay-open-quicklinks').click(evt => this.openQuickLinks(evt));
    $('.findoverlay__tab').click(evt => this.selectTabEvent(evt));
    $('.js-findoverlay-avon-select-type').click(evt => this.onSelectAvonType(evt));

    for( let node of document.querySelectorAll(".findoverlay__close") )
      node.addEventListener("click", ev => this.closeOverlays());

    dompack.qSA('.findoverlay__back').forEach( node => node.addEventListener("click", ev => this.goBack(ev)) );

    // setup search car functionality
    dompack.register(".findoverlay__inputs--vehicle input", node => this.setupSearchCar(node));

    // FIXME rolled out below:  this.selectCarType('car'); //FIXME: what to do for Avon? this used to be done on the homepage when selecting a car type (at least @ Cooper)


    //FIXME is this still needed somewhere? this call was in selectCarType but was never invoked. image doesn't seem to exist eitehr?
    // if (cartype.toUpperCase() === 'MOTORCYCLE')
    //   $('#helpersearch-legendaimg').attr('src', whintegration.config.imgroot + 'tire-size-legenda-motorcycle.jpg');

    // here ends selectCarType


    if (dompack.debugflags["cooperfindoverlay"])
      dompack.qS('.header__bottom-bar-main-link[data-tag="find_tyres"]').click();

    dompack.qS('.findoverlay__form--searchforvehicle').addEventListener('submit', evt =>
    {
      datalayer.triggerEvent("coopertires:home_searchforvehicle", { ds_cartype: this.getCarType(), ds_query: datalayer.getSearchQuery() });
    });
    dompack.qS('.findoverlay__form--searchforsize').addEventListener('submit', evt =>
    {
      let query = this.getSizeQuery();
      datalayer.triggerEvent("coopertires:home_searchforsize", { ds_cartype: query.type, ds_selwidth: query.width, ds_selserie: query.serie, ds_selrim: query.rim });
    });
  }

  updateAvailableControls()
  {
    console.error("update!");
  }

  checkFirstActivate()
  {
    if(this._activated)
      return;

    this._activated = true;
    this.updateFilters();
    this.updateMotorsportFilters();
  }

  setDealearSearchInput(val) {
    this.$dealerSearchInput.val(val);

    $('.findoverlay__searchbutton--location').attr('disabled', val == '' ? 'disabled' : null);

    let $label = this.$dealerSearchInput.closest('.wh-form__fieldgroup').find('.wh-form__label');
    if (val == '')
      $label.show();
    else
      $label.hide();
  }

  async useMyLocation(evt) {
    $('#findoverlay-use-my-location-loading').show();
    let location = await requestLocation(evt, true);

    // datalayer.triggerEvent("coopertires:dealer_locationreq", { db_locavailable: false
    //                                                          , dn_loctime: Date.now() - requeststart
    //                                                          , ds_loctype: 'browser'
    //                                                          });

    // redirect to dealer locator
    if (location) {
      let url = new URL(whintegration.config.site.page_dealer_locator);
      url.searchParams.set("position", `(${location.coords.latitude},${location.coords.longitude})`);
      navigateTo(url.toString());
      return;
    } else {
      swal({
        title: '',
        text: getTid("coopertires_sites:site.locationunavailable"),
        type: 'error',
      });
    }

    $('#findoverlay-use-my-location-loading').hide();
  }

  onFindTiresOrStoreClick(evt, type)
  {
    let isTires = type == 'tires';
    if (evt)
      evt.preventDefault();

    this.checkFirstActivate();
    document.documentElement.classList.add('header-find-container-active');
    document.documentElement.classList.remove('header-find-tires-container-active');
    document.documentElement.classList.remove('header-find-store-container-active');
    document.documentElement.classList.remove('header-search-active');
    $('.js-toggle-mobilemenu').removeClass('is-active');
    document.documentElement.classList.remove('menu-open');

    $('.header__bottom-bar-main-link').removeClass('selected');

    $('.findoverlay').removeClass('show');
    if (isTires) {
      $('.header__bottom-bar-main-link[data-tag="find_tyres"]').addClass('selected');
      $('.findoverlay--findtires').addClass('show');
      document.documentElement.classList.add('header-find-tires-container-active');
    }
    else {
      $('.header__bottom-bar-main-link[data-tag="find_store"]').addClass('selected');
      $('.findoverlay--findstore').addClass('show');
      document.documentElement.classList.add('header-find-store-container-active');
    }

    examplecontainer.checkAnimations();
  }

  onToggleFindTiresOverlay(evt)
  {
    dompack.stop(evt);

    if (document.documentElement.classList.contains('header-find-tires-container-active'))
      this.closeOverlays();
    else
      this.onFindTiresClick();
  }

  onToggleFindStoreOverlay(evt)
  {
    dompack.stop(evt);

    if (document.documentElement.classList.contains('header-find-store-container-active'))
      this.closeOverlays();
    else
      this.onFindStoreClick();
  }

  onFindTiresClick(evt)
  {
    this.setSearchCarType('car');
    this.onFindTiresOrStoreClick(evt, 'tires');
  }

  onFindStoreClick(evt) {
    this.onFindTiresOrStoreClick(evt, 'store');
  }

  // public
  openTiresPopup() {
    this.onFindTiresOrStoreClick(null, 'tires');
  }

  onSelectAvonType(evt)
  {
    evt.preventDefault();
    let type = evt.target.closest('.js-findoverlay-avon-select-type').dataset.tag;
    this.selectAvonType(type, { source: 'headerclick' });
  }

  setSearchCarType(cartype)
  {
    if (!['car','van','motorcycle','motorsport'].includes(cartype))
      throw new Error(`invalid type '${cartype}'`);

    this.cartype = cartype;
    if(document.documentElement.classList.contains('avon')) //Actually we should just check if we had a car-selection. if not, don't filter because the final tireoverview page won't understand
    {
      dompack.qSA('input[type=hidden][name=cartype]').forEach(node => node.value = cartype);
      //we also need to preselect the proper tyres for 'browse all tyres'
      if(this.tireoverviewlink)
      {
        this.tireoverviewlink.searchParams.set("cartype", cartype == "car" ? "car|suv" : cartype);
        dompack.qSA(".findoverlay__tireoverviewlink").forEach(node => node.href = this.tireoverviewlink.toString());
      }
    }
    //as car and motorcycle share an example container... update JIT
    dompack.qSA('.findoverlay__examplecontainer[data-car-examples]').forEach(
      node => node.dataset.examples = node.dataset[cartype == 'motorcycle' ? 'motorcycleExamples' : 'carExamples']);

    if (document.documentElement.classList.contains('avon')) {
      if (cartype === 'motorcycle')
        $('#helpersearch-legendaimg').attr('src', whintegration.config.imgroot + 'avon-tire-size-motorcycle-legenda.png');
      else
        $('#helpersearch-legendaimg').attr('src', whintegration.config.imgroot + 'avon-tire-size-car-legenda.png');
    }

    if (cartype == 'motorsport')
      this.selectTab('area');
    else
      this.selectTab('vehicle');

    this._doUpdateFilters();
  }

  goBack()
  {
    if( !document.documentElement.classList.contains('findoverlay-avon-enabled') )
      return;

    document.documentElement.classList.remove('findoverlay-avon-enabled');
    document.documentElement.classList.remove('findoverlay-avon-enable-categories');
    document.documentElement.classList.remove('findoverlay-avon-enable-search');
  }

  // public
  selectAvonType(type, options)
  {
    const source = options ? options.source : '';
    if(!source)
      console.error("Missing source for selectAvonType");

    this.setSearchCarType(type);
    datalayer.triggerEvent("coopertires:home_cartype", { ds_cartype: type, ds_query: datalayer.getSearchQuery(), ds_typesource: source });
    document.documentElement.classList.add('findoverlay-avon-enabled');

    document.documentElement.classList.toggle('findoverlay-avon-enable-categories', type == 'motorsport');
    document.documentElement.classList.toggle('findoverlay-avon-enable-search', type != 'motorsport');
    document.documentElement.classList.toggle('findoverlay-avon--motorcycles', type == 'motorcycle');

    if (type == 'motorsport')
      this.selectTab('area');
    else
      this.selectTab('vehicle');

    examplecontainer.checkAnimations();
  }

  // public
  closeOverlays() {
    $('.header__bottom-bar-main-link').removeClass('selected');
    $('.findoverlay').removeClass('show');
    document.documentElement.classList.remove('header-find-container-active');
    document.documentElement.classList.remove('header-find-tires-container-active');
    document.documentElement.classList.remove('header-find-store-container-active');
  }

  openQuickLinks(evt) {
    evt.preventDefault();
    document.documentElement.classList.remove('findoverlay-search-enabled');
  }

  selectTabEvent(evt) {
    let $selectedTab = $(evt.target).closest('.findoverlay__tab');
    let type = $selectedTab.data('type');
    this.searchtype = type;
    if (type == 'link')
    {
      datalayer.triggerEvent("coopertires:home_browseall", { ds_cartype: this.getCarType(), ds_query: datalayer.getSearchQuery() });
      return;
    }

    evt.preventDefault();

    if (type == 'none') // only prevent
      return;

    this.selectTab(type);
  }

  selectTab(type) {
    $('.findoverlay__tab').removeClass('selected');
    $(`.findoverlay__tab[data-type="${type}"]`).addClass('selected');

    datalayer.triggerEvent("coopertires:home_searchtype", { ds_cartype: this.getCarType(), ds_query: datalayer.getSearchQuery(), ds_searchtype: type });

    this.selectTabContainer(type);
  }

  selectTabContainer(type) {
    $('.findoverlay__tabcontainer').removeClass('enabled');
    $(`.findoverlay__tabcontainer[data-type="${type}"]`).addClass('enabled');
  }

  setupSearchCar(carsearch) {
    let suggester = new cSearchSuggest(carsearch, text => this.getSearchSuggestions(text), { minwordlength:1, triminput: false});
    carsearch.addEventListener("focus", () => this.prepareModelSearch());
    carsearch.addEventListener("input", () => this.prepareModelSearch());
    carsearch.addEventListener("dompack:experimental--autosuggested", evt => this.onAutoSuggested(evt, suggester));
    carsearch.addEventListener("dompack:experimental--suggestions", evt => this.onGiveSuggestions(evt, suggester));
    carsearch.addEventListener("dompack:experimental--closesuggestions", evt => this.onRemoveSuggestions(evt, suggester));
  }

  //FIXME: car type selection is no longer a part of the search... and it seems it's required to have a type
  getCarType() {
    return this.cartype;
  }

  async getSearchSuggestions(text) {
    let start = Date.now();
    console.log("* Getting suggestions for [" + text + "]");

    let type = this.getCarType();
    let suggestions = await ModelSearch.suggestCompletions(type,text);
    let numsuggestions = suggestions.values.length;
    let time_ms = Date.now() - start;

    console.log("* " + numsuggestions + " suggestions in " + time_ms + "ms", suggestions);
    datalayer.triggerEvent("coopertires:home_suggestions", { ds_cartype: this.getCarType()
                                                           , ds_query: text
                                                           , ds_firstsuggestion: suggestions.values.length ? suggestions.values[0].value : ""
                                                           , dn_numsuggestions: numsuggestions
                                                           , dn_timetaken: time_ms
                                                           });
    return suggestions;
  }

  prepareModelSearch()
  {
    ModelSearch.prepare(this.getCarType());
  }

  //enable, disable search button based on whether the current suggestion is good. not too happy about this yet...
  onAutoSuggested(evt, suggester)
  {
    if(evt.detail.item.isfinal)
    {
       datalayer.triggerEvent("coopertires:home_buttonenabled", { ds_cartype: this.getCarType(), ds_query: datalayer.getSearchQuery(), db_buttonenabled: true });
      dompack.qS('button.findoverlay__searchbutton--vehicle').disabled = false;
    }
    else
    {
      suggester.openSuggestions();
    }
  }

  onGiveSuggestions(evt, suggester)
  {
    let haveonefinal = (evt.detail.suggestions.length == 1
                        || (evt.detail.suggestions.length > 1 && evt.detail.suggestions[0].value == document.querySelector("#searchcarbymodel").value))
                       && evt.detail.suggestions[0].isfinal;

    datalayer.triggerEvent("coopertires:home_buttonenabled", { ds_cartype: this.getCarType(), ds_query: datalayer.getSearchQuery(), db_buttonenabled: !!haveonefinal });
    dompack.qS('button.findoverlay__searchbutton--vehicle').disabled = !haveonefinal;
  }

  onRemoveSuggestions(evt, suggester) {
  }

  //////////////////////////////////////
  //
  // Size searching
  //

  async updateFilters(event)
  {
    let lock = dompack.flagUIBusy()
    try
    {
      return await this._doUpdateFilters(event);
    }
    finally
    {
      lock.release();
    }
  }

  async _doUpdateFilters(event)
  {
    if (this.updating_selects)
      return;

    this.updating_selects = true;

    //Get query, from page cache if possible
    let query = this.getSizeQuery();
    document.documentElement.classList.toggle("findoverlay-show-application", query.select_application);
    dompack.qS(`#searchforsizeform input[name="cartype"]`).value = query.type;
    dompack.qS(`#searchforsizeform .findoverlay__searchbutton`).disabled = (query.select_application && !query.type);

    let result;

    let must_select_applicability = query.select_application && !query.type && this.searchtype == 'size';
    if(must_select_applicability) //must select type, but not done yet
    {
      result = { widths:[], series:[], rims: [] };
    }
    else
    {
      let sendquery = { ...query, select_application: undefined };
      let queryjson = JSON.stringify(sendquery);
      result = resultcache[queryjson];
      if(!resultcache[queryjson])
      {
        result = resultcache[queryjson] = CarDBService.getTireSizeOptions(this.searchinfo.channel, sendquery);
      }

      result = await result;
    }

    dompack.qSA(".findoverlay__input--select:not(.findoverlay__input__application), .findoverlay__searchbuttoncontainer").forEach(node => node.classList.toggle("findoverlay__input--precondition-first", must_select_applicability));

    if (event)
    {
      // The user changed one of the size pulldowns
      let pxldata = { ds_cartype: query.type, ds_selwidth: query.width, ds_selserie: query.serie, ds_selrim: query.rim, db_anyresults: !!result };
      if (pxldata.db_anyresults)
        pxldata = { ...pxldata, dn_numwidths: result.widths.length, dn_numseries: result.series.length, dn_numrims: result.rims.length };
      datalayer.triggerEvent("coopertires:home_searchsizes", pxldata);
    }
    else
    {
      // The car type was changed
      let pxldata = { ds_cartype: query.type, db_anyresults: !!result };
      if (pxldata.db_anyresults)
        pxldata = { ...pxldata, dn_numwidths: result.widths.length, dn_numseries: result.series.length, dn_numrims: result.rims.length };
      datalayer.triggerEvent("coopertires:home_initsizes", pxldata);
    }

    if (result && result.widths) {
      let widths = result.widths.map(width => { return { title: width, value: width }; });
      widths.unshift({ title: getTid("coopertires_sites:site.select"), value: "" });
      updateSelectOptions(dompack.qS(".findoverlay select[name='width']"), widths);

      $('.findoverlay select[name="width"]').selectmenu('refresh');
    }

    if (result && result.series) {
      let series = result.series.map(serie => { return { title: serie, value: serie }; });
      // series can be empty if a width in inch notation (e.g. 30X9.50) is chosen
      if (series.length || result.widths.length == 0) //but don't show "--" if we don't have any selectors at all (ie width is empty too..)
        series.unshift({ title: getTid("coopertires_sites:site.select"), value: "" });
      else
        series = [ { title: "—", value: "" }];
      updateSelectOptions(dompack.qS(".findoverlay select[name='aspectratio']"), series);

      $('.findoverlay select[name="aspectratio"]').selectmenu('refresh');
    }

    if (result && result.rims) {
      let rims = result.rims.map(rim => { return { title: rim, value: rim }; });
      rims.unshift({ title: getTid("coopertires_sites:site.select"), value: "" });
      updateSelectOptions(dompack.qS(".findoverlay select[name='rimsize']"), rims);

      $('.findoverlay select[name="rimsize"]').selectmenu('refresh');
    }

    this.updating_selects = false;
  }

  getSizeQuery()
  {
    let cartype = document.documentElement.classList.contains('avon') ? this.getCarType() : ''
    let type = "";
    let select_application = cartype == 'motorcycle';
    if(select_application)
    {
      let application = dompack.qS("#findoverlay__application").value;
      if(application)
        type = application == 'track' ? 'racingbike' : 'motorcycle';
      //else: we leave unset, you must select a application first
    }
    else
    {
      type = cartype;
    }

    return { select_application: select_application
           // , application: select_application ? dompack.qS(".findoverlay select[name='application']").value : ''
           , width: dompack.qS(".findoverlay select[name='width']").value
           , serie: dompack.qS(".findoverlay select[name='aspectratio']").value
           , rim: dompack.qS(".findoverlay select[name='rimsize']").value
           , type
           };
  }

  //////////////////////////////////////
  //
  // Motorsport size searching
  //

  async updateMotorsportFilters(event)
  {
    if (this.updating_motorsport_selects || !dompack.qS(".findoverlay select[name='construction']")) //already updating or not even on a motorsize page...
      return;

    this.updating_motorsport_selects = true;

    let query = this.getdimensionQuery();

    let result = await CarDBService.getMotorsportTireSizeOptions(this.searchinfo.channel, query);
    /*if (event)
    {
      // The user changed one of the size pulldowns
      let pxldata = { ds_cartype: query.type, ds_selwidth: query.width, ds_selserie: query.serie, ds_selrim: query.rim, db_anyresults: !!result };
      if (pxldata.db_anyresults)
        pxldata = { ...pxldata, dn_numwidths: result.widths.length, dn_numseries: result.series.length, dn_numrims: result.rims.length };
      datalayer.triggerEvent("coopertires:home_searchsizes", pxldata);
    }
    else
    {
      // The car type was changed
      let pxldata = { ds_cartype: query.type, db_anyresults: !!result };
      if (pxldata.db_anyresults)
        pxldata = { ...pxldata, dn_numwidths: result.widths.length, dn_numseries: result.series.length, dn_numrims: result.rims.length };
      datalayer.triggerEvent("coopertires:home_initsizes", pxldata);
    }*/
  // console.info(query,result);

    if (result && result.rims) {
      let rims = result.rims.map(rim => { return { title: rim, value: rim }; });
      rims.unshift({ title: getTid("coopertires_sites:site.select"), value: "" });
      updateSelectOptions(dompack.qS(".findoverlay select[name='rimsize_motorsport']"), rims);

      $('.findoverlay select[name="rimsize_motorsport"]').selectmenu('refresh');
    }

    if (result && result.dimensions) {
      let dimensions = result.dimensions.map(dimension => { return { title: dimension, value: dimension }; });
      dimensions.unshift({ title: getTid("coopertires_sites:site.select"), value: "" });
      updateSelectOptions(dompack.qS(".findoverlay select[name='tyresize']"), dimensions);

      $('.findoverlay select[name="tyresize"]').selectmenu('refresh');
    }

    this.updating_motorsport_selects = false;
  }

  getdimensionQuery()
  {
    return {
      construction: dompack.qS(".findoverlay select[name='construction']").value,
      rim: dompack.qS(".findoverlay select[name='rimsize_motorsport']").value,
      dimension: dompack.qS(".findoverlay select[name='tyresize']").value,
      type: "motorsport"
    };
  }
}

let theFindOverlay = null; //singleton, only one may exist. must appear BELOW to prevent the class from using it

dompack.onDomReady(() =>
{
  theFindOverlay = new FindOverlay();
  theFindOverlay.initialize();

  //Avon homepage has direct links to the overlay
  dompack.qSA('.js-home-trigger-find-tires').forEach(node =>
  {
    node.addEventListener("click", evt =>
    {
      dompack.stop(evt);

      let type = evt.target.closest('.js-home-trigger-find-tires').dataset.tag;
      theFindOverlay.selectAvonType(type, {source: 'carclick'});
      theFindOverlay.openTiresPopup();
    })
  })
});

export function closeAnyOverlay()
{
  if(theFindOverlay)
    theFindOverlay.closeOverlays();
}
export function openFindStore()
{
  if(theFindOverlay)
    theFindOverlay.onFindStoreClick(null);
}

dompack.register('.findoverlay__form--searchforlocation', node => node.addEventListener('submit', handleLocationLookupFormSubmit));
