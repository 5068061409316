import * as dompack from "dompack";
import * as whintegration from "@mod-system/js/wh/integration";

import "./egghunt.scss";

let activeeggs = [];

function createEgg()
{
  let eggnode = <a class="egghunt__egg"></a>;
  return eggnode;
}

function insertEgg(cssrule, which, eggnode)
{
  if(dompack.debugflags.egghunt)
    console.log(`[egghunt] Selector `, cssrule, ' #' + which);

  try
  {
    let rulematch = document.querySelectorAll(cssrule);
    if(!rulematch.length)
      return "There are no elements matching your selector";
    if(which > rulematch.length)
      return `There are only ${rulematch.length} elements matching your selector, you wanted #${which}`;

    let position = rulematch[which-1].matches("a") ? "after" : "append";
    if(dompack.debugflags.egghunt)
      console.log(`[egghunt] Inserting egg at `, rulematch[which-1], ' position', position)
    rulematch[which-1][position](eggnode);
    return "";
  }
  catch(e)
  {
    return e.toString();
  }
}

function testEgg()
{
  let url = new URL(location.href);
  let cssrule = url.searchParams.get("testegg_cssrule");
  let which = parseInt(url.searchParams.get("testegg_which"));

  let egg = createEgg();
  egg.addEventListener('click', () => alert("You found the test egg"));

  let error = insertEgg(cssrule, which, egg);
  if(error)
    alert(error);
  else
    egg.scrollIntoView({smooth:true});
}

async function processEggs(egginfo)
{
  let useurl = location.href.split('#')[0];
  const msgUint8 = new TextEncoder().encode(useurl);                               // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
  let hash = hashArray.map(b => b.toString(16).padStart(2, '0')).join('').toUpperCase(); // convert bytes to hex string

  let seeneggs = [];
  for(let egg of egginfo.eggs.filter(egg => egg.locations.some(loc => loc.url == hash)))
  {
    for(let loc of egg.locations.filter(loc => loc.url == hash))
    {
      seeneggs.push(loc.id);
      if(!activeeggs.find(_ => _.id == loc.id))
      {
        let eggnode = createEgg();
        eggnode.href = egg.claimform + "?claimedurl=" + encodeURIComponent(useurl);
        insertEgg(loc.cssrule, loc.which, eggnode);
        activeeggs.push({ id: loc.id
                        , node: eggnode
                        });
      }
    }
  }

  //clear obsolete eggs
  for(let killegg of activeeggs.filter(_ => !seeneggs.includes(_.id)))
    killegg.node.remove();

  activeeggs = activeeggs.filter(_ => seeneggs.includes(_.id));
}

async function setupEgghunt()
{
  let egginfo, lastpoll, shouldfetch = true;
  try
  {
    egginfo = JSON.parse(localStorage.eggHuntInfo);
    lastpoll = parseInt(localStorage.eggHuntLastPoll);

    if(egginfo && lastpoll && Date.now() < egginfo.pollfrequency + lastpoll)
      shouldfetch = false;
  }
  catch(ignore)
  {
  }

  if(shouldfetch)
  {
    let polltime = Date.now();
    egginfo = await (await fetch("/.coopertires_sites/egghunt/egghunt.json")).json();
    try
    {
      localStorage.eggHuntInfo = JSON.stringify(egginfo);
      localStorage.eggHuntLastPoll = polltime;
    }
    catch(ignore)
    {
    }
  }

  if(dompack.debugflags.egghunt)
    console.log('[egghunt] egg info', {shouldfetch}, egginfo);

  setTimeout(setupEgghunt, egginfo.pollfrequency);
  processEggs(egginfo);
}

if(location.href.indexOf('testegg_cssrule') != -1)
  dompack.onDomReady(testEgg);
// else  ///disable egghunt until we're using it again
  // setupEgghunt();
