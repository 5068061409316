import * as CarDBService from "@mod-coopertires_sites/js/cardbservice.rpc.json";
import * as bounds from 'binary-search-bounds';
import * as dompack from 'dompack';

let modeldbtype, modeldbpromise, modeldb;
let variantpromises = {};
let debug = dompack.debugflags["cooper-modelsearch"];

async function getVariants(brand, model)
{
  let vk = brand.brand + '-' + model.model;
  if(!variantpromises[vk])
    variantpromises[vk] = CarDBService.getVariants(vk, modeldbtype);
  let variants = await variantpromises[vk];
  return variants;
}

//invoking us will start loading the database, speeding up responses
export async function prepare(carType)
{
  if (!carType)
    return;

  //ADDME we can optimize further by loading a static JSON file instead of doing a RPC
  if(!modeldbpromise || modeldbtype != carType)
  {
    modeldbpromise = CarDBService.getSearchDatabase(carType);
    modeldbtype = carType;
  }

  modeldb = await modeldbpromise;
  window.modeldb = modeldb;

  return modeldb;
}

function strcmpi(lhs,rhs)
{
  lhs = lhs.toUpperCase();
  rhs = rhs.toUpperCase();
  return lhs < rhs ? -1 : lhs === rhs ? 0 : 1;
}
function compareWordMatch(lhs,rhs)
{
  return strcmpi(lhs.word, rhs.word);
}
function compareTitleMatch(lhs,rhs)
{
  let cmplhs = tokenizeCarTitle(lhs.title).join(' ');
  let cmprhs = tokenizeCarTitle(rhs.title).join(' ');
  let res = strcmpi(cmplhs,cmprhs);
  return res;
}
function compareValueMatch(lhs,rhs)
{
  return strcmpi(lhs.value, rhs.value);
}
function compareAnyMatch(lhs, rhs)
{
  if (typeof lhs == "number")
    return lhs - rhs;
  return strcmpi(lhs, rhs);
}
function lookupBrand(brand)
{
  return modeldb.brands[bounds.eq(modeldb.brands, {brand:brand}, (lhs,rhs) => compareAnyMatch(lhs.brand, rhs.brand))] || null;
}
function lookupModel(brand, model)
{
  return brand.models[bounds.eq(brand.models, {model:model}, (lhs,rhs) => compareAnyMatch(lhs.model, rhs.model))];
}

function getBrandsInMatches(matches)
{
  return matches.filter(match => match.model == 0);
}

//get any brand in the matchlist
function getBrands(matchlist)
{
  let matches = [];
  for(let wordmatch of matchlist)
    matches.push(...getBrandsInMatches(wordmatch.matches));

  return matches;
}

function postProcessMatches(matches)
{
  return matches.map(match => ({...match, title: getBrandModelTitle(match.brand, match.model)}));
}

function getWordRange(word)
{
  let matchstart = bounds.ge(modeldb.wordlist, { word: word}, compareWordMatch);
  let matchlimit = bounds.ge(modeldb.wordlist, { word: word + '\x7F'}, compareWordMatch);
  let wordmatches = modeldb.wordlist.slice(matchstart, matchlimit);

  let exactmatch = wordmatches.length > 0 && wordmatches[0].word.toUpperCase() == word.toUpperCase() ? wordmatches[0] : null;
  if(exactmatch)
    exactmatch.matches = postProcessMatches(exactmatch.matches);

  return { wordmatches
         , exactmatch
         };
}
function getModelRange(brand, words)
{
  //did we generate a sorted version yet? (models is sorted ny id)
  if(!brand.titlesortedmodels)
    brand.titlesortedmodels = [...brand.models].sort(compareTitleMatch);

  let matchstart = bounds.le(brand.titlesortedmodels, { title: words.join(' ').trim() }, compareTitleMatch);
  let matchlimit = bounds.ge(brand.titlesortedmodels, { title: words.join(' ').trim() + '\x7F' }, compareTitleMatch);

  let modelmatches = brand.titlesortedmodels.slice(matchstart, matchlimit);
  let res = { modelmatches
            , exactmatch: modelmatches.length > 0 && tokenizeCarTitle(modelmatches[0].title.toUpperCase()).join(' ') == words.join(' ').toUpperCase() ? modelmatches[0] : null
            };
  return res;
}
function getBrandModelTitle(brandid, model)
{
  let brand = lookupBrand(brandid);
  let matchmodel = lookupModel(brand,model);

  return brand.title + (matchmodel ? " " + matchmodel.title : "");
}

//tokenize query to words. every digit/letter switch or space starts a new word..
function tokenizeCarTitle(indata)
{
  let toks = [];
  let ctr = 0;
  while(indata.length)
  {
    //if(++ctr>100)      throw new Error("tokenizecartitle no forward progress [" + indata + "]");

    //match groups of letters, digits... or just trash until the next space
    let tokenized = indata.match(/^([a-z]+|[0-9]+|[^a-z0-9]*)(.*)$/i);
    if(!tokenized)
      break;
    let tok = tokenized[1].trim()
    if(tok)
      toks.push(tok);
    indata=tokenized[2].trim();
  }
  return toks;
}

//suggest based on title so far
export async function suggestCompletions(cartype, textsofar)
{
  await prepare(cartype);

  let words = tokenizeCarTitle(textsofar);
  let currentbrand = null, lastrange = null;

  if(words.length>0 && words[0])
  {
    lastrange = getWordRange(words[0]);
    if(debug)
      console.log("Try brandmatch first word: ", words[0], lastrange);

    if(lastrange.exactmatch)
    {
      let brands = getBrandsInMatches(lastrange.exactmatch.matches);
      if(debug)
        console.log("Candidate brands: ",brands);

      //ensure that 'Ducati' which gives both 'Ducati' and 'Pierobon-Ducati' as matches just exactly matches Ducati
      let exactbrandmatch = brands.filter(brand => brand.title.toUpperCase() == words[0].toUpperCase())[0];
      if(exactbrandmatch)
      {
        if(debug)
          console.log("Exact brand match fully entered, switch to that", exactbrandmatch);
        brands = [exactbrandmatch];
      }

      if(brands.length > 1 && words.length > 1) //multiple matches, eg Moto from Moto Guzzi
      {
        words.shift();

        let matchingbrands = brands.map(brandrecord => brandrecord.brand);
        let secondwordrange = getWordRange(words[0]);
        if(debug)
          console.log("Try brandmatch second word: ", words[0], secondwordrange);

        let secondwordexactmatch = secondwordrange.exactmatch;
        if (secondwordexactmatch)
        {
          //check for exact brand match
          let exactbrandmatches = secondwordexactmatch.matches.filter(match => match.model == 0 && matchingbrands.includes(match.brand));
          if(exactbrandmatches.length == 1)
          { //exact brand match, finally!
            words.shift(); //also remove this word
            currentbrand = lookupBrand(exactbrandmatches[0].brand);
          }
        }

        if(!currentbrand) //no brand match yet
        {
          //Filter the wordmatches, see if they have any further matches for the brand
          let secondword_brandmatches = [];
          for(let wordmatch of secondwordrange.wordmatches)
            for(let brandmatch of wordmatch.matches)
              if(brandmatch.model == 0 && matchingbrands.includes(brandmatch.brand))
              {
                secondword_brandmatches.push({value: getBrandModelTitle(brandmatch.brand, 0) });
                //and remove it from the list to prevent duplicating
                matchingbrands = matchingbrands.filter(_ => _ != brandmatch.brand);
              }

          if(secondword_brandmatches.length > 0) //when both brands and models match (eg MOTO G for MOTO GUZZIE) - only offer the brands
            return { values: secondword_brandmatches.sort(compareValueMatch) };
        }
      }
      else if(brands.length == 1) //We've got an exact brand match!
      {
        currentbrand = lookupBrand(brands[0].brand);
        words.shift();

        let brandtoks = tokenizeCarTitle(currentbrand.title).slice(1);
        if(debug)
          console.log("Exact brand match for ", currentbrand, " remaining words ",words, " remaining brand toks", brandtoks);

        while(words[0] && brandtoks.length)
        {
          //full completion?
          if(words[0].toUpperCase() == brandtoks[0].toUpperCase())
          {
            words.shift();
            brandtoks.shift();
          }
          else //partial completion of the rest of the brand?
          {
            return { values: [ {value: currentbrand.title} ] };
          }
        }
      }
    }
  }

  if(debug)
    console.log("After brand lookup, words: ", words, " currentbrand ", currentbrand);

  let currentmodel = null;

  let suggestions = [];

  if(words.length>0 && words[0]) //can we extract a model?
  {
    lastrange = getWordRange(words[0]);
    if(debug)
      console.log("model match range: " , lastrange, "currentbrand=", currentbrand, " words were: ", words);



/*
    if(!lastrange.wordmatches.length && words[0].length>1) //no matches in sight ?
    {
      //maybe you wanted to enter a space but didn't do so yet. (eg '320 i'). let's try matching the shorter string - FIXME do we still need this?
      let tryword = words[0].substr(0, words[0].length-1);
      if(debug)
        console.log("retry lookup with [" + tryword + "]");

      let trythisrange = getWordRange(tryword);
      if(trythisrange.wordmatches.length) //that seemed to work?
      {
        //fix up the input. move the first letter to the second word
        words = [ tryword, words[0].substr(words[0].length-1) + (words[1] || ''), ...words.slice(2) ];
        if(debug)
          console.log("Rematch after splitting the first word", trythisrange, words);

        lastrange = trythisrange;
      }
    }
*/

    /* FIXME this if check used to say: the current word matches one model exactly
       but what it seems to do: check if we're matching models
       and this broke on Avon as "MOTO" is both a brand and a model.

       what I think we intended: all matches should point to a model, not a brand. this is the 'corolla' direct match situation */
    if(lastrange.exactmatch)// && lastrange.exactmatch.matches.filter(match => !match.model).length == 0)
    {
      if(!currentbrand)
      {
        words = words.slice(1); //strip the matched word
        let values = lastrange.exactmatch.matches.map(match => ({ value: getBrandModelTitle(match.brand, match.model), isfinal: false }));

        //post filter what we can...
        for(let word of words)
        {
          //see if we can reduce the list by trying to apply this word to the list of results
          let filteredvalues = values.filter(val =>
          {
            for(let valword of tokenizeCarTitle(val.value))
              if(valword.toUpperCase().startsWith(word.toUpperCase()))
                return true;
          });

          if(filteredvalues.length) //this didn't just eliminate everything
            values = filteredvalues;
        }

        values = values.sort(compareValueMatch);
        return { values: values };
      } //!currentbrand

      //lookup the current word as a modelrange
      let modelrange = getModelRange(currentbrand, words);
      if(debug)
        console.log("currentbrand=",currentbrand,"words=",words,"modelrange=",modelrange);

/*
      if(modelrange.modelmatches.length == 1 || modelrange.exactmatch)
      { //One match
        //then remove the matching part and continue matching variants
        currentmodel = lookupModel(currentbrand, modelrange.modelmatches[0].model);
        if(debug)
          console.log("exact match, trim words", currentmodel, words);
        words = words.slice(tokenizeCarTitle(currentmodel.title).length);
        if(debug)
          console.log("exact match, trim result", currentmodel, words);
      }*/
    }
  }

  if(debug)
    console.warn("state:", words, currentbrand, currentmodel, lastrange);

  if(!currentbrand)
  {
    let brands = getBrands(lastrange.wordmatches);
    if(debug)
      console.log("!currentbrand, brands=",brands);
    if(brands.length >= 1)
      return { values: brands.map(match => ({ value: lookupBrand(match.brand).title, isfinal: false })).sort(compareValueMatch) };
  }

  if(currentbrand && !currentmodel)
  {
    //looking for a model!
    if(!words[0]) // list all brands
    {
      let brands = currentbrand.models.map(model => ({ value: currentbrand.title + " " + model.title }));
      if(debug)
        console.log("currentbrand && !currentmodel, brands=",brands);
      return { values: brands.sort(compareValueMatch) };
    }
  }

  //We have no brands to suggest. Let's try models
  let modelsuggestions = [];

  //We're still searching for your model. Offer the current available continuations
  for(let wordmatch of lastrange.wordmatches)
    for(let match of wordmatch.matches)
    {
      if(currentbrand && match.brand != currentbrand.brand)
        continue;
      if(!match.model || (currentmodel && currentmodel.model == match.model))
        continue;

      let brand = currentbrand || lookupBrand(match.brand);
      let model = lookupModel(brand, match.model);
      modelsuggestions.push(brand.title + " " + model.title);
    }

  if(currentbrand) //#313 - initial titles first
    modelsuggestions = modelsuggestions.sort((lhs,rhs) => sortInitialMatchFirst(currentbrand.title + " " + words.join(" "), lhs, rhs));
  else
    modelsuggestions = modelsuggestions.sort();

  modelsuggestions = modelsuggestions.map(match => ({ value: match, isfinal: false }));

  let variantsuggestions=[];
  if(currentbrand)
  {
    //do we have one or more exact model matches?
    for(let i=1;i<=words.length;++i)
    {
      let tryset = words.slice(0,i).join(' ');
      let candidate = currentbrand.models.filter(mdl => tokenizeCarTitle(mdl.title.toUpperCase()).join(' ')== tryset.toUpperCase())[0];
      if(candidate)
      {
        let variants = await getVariants(currentbrand, candidate);
        let varianttofind = words.slice(i).join(' ');
        if(debug)
          console.log("find", varianttofind, "in", variants);

        variants = variants.filter(variant => tokenizeCarTitle(variant.title.toUpperCase()).join(' ').startsWith(varianttofind.toUpperCase()));

        variants = variants.map(variant =>
                                 {
                                   // For motorcycles, there is no distinction between model and variant, so we'll remove the
                                   // model title from the variant title to avoid showing things like "Aprilia Area 51 Area 51
                                   // 1998 - 2005"
                                   let varianttitle = variant.title;
                                   if (varianttitle.indexOf(candidate.title) === 0)
                                     varianttitle = varianttitle.substr(candidate.title.length).trim();
                                   return { value: currentbrand.title + " " + candidate.title + " " + varianttitle, isfinal: true };
                                 });
        if(variants.length)
        {
          //no need to list the model in the results
          modelsuggestions = modelsuggestions.filter(_ => _.value != currentbrand.title + " " + candidate.title);
          variantsuggestions = variantsuggestions.concat(variants);
        }
      }
    }
  }

/*  if(currentmodel)
  {
    let variants = await getVariants(currentbrand, currentmodel);
    let varianttofind = words.join(' ');
    if(debug)
      console.log("find", varianttofind, "in", variants);

    variants = variants.filter(variant => tokenizeCarTitle(variant.title.toUpperCase()).join(' ').startsWith(varianttofind.toUpperCase()));

    variants = variants.map(variant =>
                             {
                               // For motorcycles, there is no distinction between model and variant, so we'll remove the
                               // model title from the variant title to avoid showing things like "Aprilia Area 51 Area 51
                               // 1998 - 2005"
                               let varianttitle = variant.title;
                               if (varianttitle.indexOf(currentmodel.title) === 0)
                                 varianttitle = varianttitle.substr(currentmodel.title.length).trim();
                               return { value: currentbrand.title + " " + currentmodel.title + " " + varianttitle, isfinal: true };
                             });

    return { values: variants.sort(compareValueMatch).concat(modelsuggestions)
           };
  }
*/
  return { values: variantsuggestions.concat(modelsuggestions) };
}

function sortInitialMatchFirst(initial, lhs, rhs)
{
  let lhs_is_initial = lhs.toUpperCase().startsWith(initial.toUpperCase());
  let rhs_is_initial = rhs.toUpperCase().startsWith(initial.toUpperCase());
  if(lhs_is_initial && !rhs_is_initial)
    return -1;
  if(!lhs_is_initial && rhs_is_initial)
    return 1;
  return lhs.toUpperCase() < rhs.toUpperCase() ? -1 : lhs.toUpperCase() > rhs.toUpperCase() ? 1 : 0;
}

window.suggestCompletions = suggestCompletions;
