/* global google */

import './tiredetails.scss';
import './tiredetails__coopermotorsport.scss';

import * as dompack from 'dompack';
import * as util from '../../shared/js/utilities';
import * as whintegration from '@mod-system/js/wh/integration';
import domtemplate from '../../shared/js/domtemplate';
import JSONRPC from "@mod-system/js/net/jsonrpc";
import { requestLocation } from '../dealer_locator/dealer_locator';
import { handleLocationLookupFormSubmit } from '../../findoverlay/findoverlay';
import * as datalayer from '../../shared/js/datalayer';
import * as dialogapi from 'dompack/api/dialog';
import * as dialog from 'dompack/components/dialog';
import getTid from "@mod-tollium/js/gettid";

import $ from 'jquery';
require('../../web/jquery-ui/jquery-ui.min.js');
require('../../shared/js/jquery.pagination.js');

let tireSizesPerPage = 20; // number of tire sizes to show when using pagination

class cTiresToolTips
{
  constructor( node )
  {
    this.node = node;
    this.tips = this.node.querySelectorAll(".p-specs__tooltip");
    for( let tnode of this.tips )
    {
      let tiptxt = tnode.textContent;//ignore empty and  missingtids
      if( tiptxt == "" || tiptxt.indexOf('(cannot find') == 0 )
        continue;

      let contentnode = tnode.querySelector(".p-specs__tooltip__content");
      this.replaceURLWithHTMLLinks(contentnode);

      tnode.parentNode.addEventListener("mousemove", ev => {
        if( !tnode.parentNode.classList.contains("p-specs__coltitle") )
          return;

        if( this.activetip )
          this.hideTip(this.activetip);

        this.activetip = tnode;
        this.orgparent = tnode.parentNode;

        let poswrapper = this.node.getBoundingClientRect();
        let postip = tnode.getBoundingClientRect();

        let w = tnode.clientWidth;
        let x = postip.left - poswrapper.left;
        let y = postip.top - poswrapper.top;

        tnode.style.width = w + "px";
        tnode.style.left = x + "px";
        tnode.style.top = y + "px";

        this.node.appendChild(tnode); //Put outside the overflow container
        tnode.clientWidth;//force css update
      });

      tnode.parentNode.addEventListener("mouseout", ev => this.hideTip(tnode) );
    }

    let fullinfobtn = this.node.querySelector(".p-specs__moreinfo");
    if(fullinfobtn)
      fullinfobtn.addEventListener("click", ev => this.showTipsDialog());
  }

  replaceURLWithHTMLLinks(node)
  {
    let text = node.textContent;
    if (text)
    {
      text = text.replace(
        /((http|https)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{1,40}(\/\S*)?)/gi,
        function(url){
          var full_url = url;
          if (!full_url.match('^https?:\/\/'))
            full_url = 'http://' + full_url;

          return '<a href="' + full_url + '" target="_blank">' + url + '</a>';
        }
      )

      node.innerHTML = text;
    }
  }

  hideTip(tnode)
  {
    if( tnode.parentNode.classList.contains("p-specs__coltitle") )
      return;

    this.orgparent.appendChild(tnode);
    this.orgparent = null;
    this.activetip = null;

    tnode.style.width = "";
    tnode.style.left = "";
    tnode.style.top = "";
  }

  showTipsDialog()
  {
    let closebtn = <span class="close fa-stack">
                     <span class="fa fa-angle-right fa-stack-1x" />
                     <span class="fa fa-angle-left fa-stack-1x" />
                   </span>;//

    let tipcontent = <div class="p-specs__alltips">
                       <div class="p-specs__alltips__header">{util.isAvonSite() ? getTid("coopertires_sites:site.js.tirespecifications_avon") : getTid("coopertires_sites:site.js.tirespecifications")}</div>
                       {closebtn}
                     </div>;//

    for( let tnode of this.tips )
    {
      if( tnode.textContent == "" || tnode.textContent.indexOf('(cannot find') == 0 )
        continue;//ignore empty and  missingtids

      let contentnode = <div class="description" />;
      contentnode.innerHTML = tnode.querySelector(".p-specs__tooltip__content").innerHTML;

      let line = <div class="p-specs__alltips__line">
                   <div class="title">{tnode.parentNode.children[0].textContent}</div>
                   {contentnode}
                 </div>;//
      tipcontent.appendChild(line);
    }

    let dialog = dialogapi.createDialog();
    dialog.contentnode.appendChild(tipcontent);

    closebtn.addEventListener("click", ev => {
      dialog.resolve();
    });

    dialog.runModal();
  }
}

function scrollToFeatures()
{
  let scrollTo = $('#features').offset().top - 30;
  $('html,body').animate({scrollTop: scrollTo}, 750);
}

function scrollToAvailableSizes()
{
  // make sure the specs container is visible to get its offset
  if ($('#specs').is(':visible') === false)
    $('#expand-specs-label').trigger('click');

  let scrollTo = $('#specs').offset().top - 30;
  $('html,body').animate({scrollTop: scrollTo}, 750);
}

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-tiredetails'))
    return;

  if(document.documentElement.classList.contains('tiredetails--singlemaster'))
  {
    //just showing one master..

    setTimeout(scrollToAvailableSizes,100); //bring it in veiw soon
  }

  let mastertitle = dompack.qS(".tiredetails__mastertitle");
  if(mastertitle)
    window.dataLayer.push({mastertitle: mastertitle.textContent.trim() });

  let tiresizeInput = dompack.qS("#tiredetails-tiresize");
  if (tiresizeInput) {
    util.applyCustomStylePulldown($(tiresizeInput), false, () => onTiresizeChange(true/*by user*/));
    onTiresizeChange(false/*by load*/);
  }

  setupFullScreenSlider();
  setupMobileSlider();
  setupExpandItems();
  setupDealerLocator();

  dialogapi.setupDialogs(options => dialog.createDialog('tiredetailsdialog', options));

  let specsnode = document.getElementById("specs");
  if( specsnode )
    new cTiresToolTips(specsnode);

  $('#tiredetails-enlarge-container').height($('#tiredetails-desktop-info-image').attr('height') + 'px');

  $('.js-scroll-to-features').click((evt) => {
    evt.preventDefault();
    datalayer.triggerEvent("coopertires:tiredetails_features");

    scrollToFeatures();
  });

  $('.js-scroll-to-specs').click((evt) => {
    evt.preventDefault();
    datalayer.triggerEvent("coopertires:tiredetails_availablesizes");

    scrollToAvailableSizes();
  });

  $(".p-specs__footnoteletter").hover(evt => {
    let pos = $(evt.target).position();
    $(`.p-specs__footnotepopup[data-letter='${evt.target.dataset.letter}']`)
      .css("top", pos.top)
      .css("left", pos.left + ($(evt.target).width() / 2))
      .addClass("p-specs__footnotepopup--show");
  }, evt => {
    $(`.p-specs__footnotepopup[data-letter='${evt.target.dataset.letter}']`).removeClass("p-specs__footnotepopup--show");
  });

  let treadpatternpopup = $(".p-specs__treadpatternpopup");
  $(".p-specs__treadpattern").click(evt =>
  {
    evt.preventDefault();
    let id = evt.target.dataset.treadPatternId;
    $(".p-specs__treadpatternpopup .p-specs__row").each(function(i)
    {
      this.style.display = this.dataset.treadPatternId == id ? "" : "none";
    });
    treadpatternpopup.addClass("p-specs__treadpatternpopup--show");
  });
  $(".p-specs__treadpatternpopup-close").click(evt =>
  {
    evt.preventDefault();
    treadpatternpopup.removeClass("p-specs__treadpatternpopup--show");
  });
});

function setupFullScreenSlider() {
  let screenIsMd = util.testBreakPoint('md');

  if (!screenIsMd) {
    let swiper = new Swiper(dompack.qS('#tiredetails-fullscreenslider-container'), {
      direction: 'horizontal',
      loop: true,
      speed: 500,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      lazy: {
        loadOnTransitionStart: true,
      },
    });
  }

  $('.p-info-images__image').click(onSlideshowOpenClick);
  $('.p-tiredetails .p-fullscreenslider__close').click(onSlideshowCloseClick);

  // copy full screen modal to modal container element (direct child of <body>)
  $('#tiredetails-fullscreenslider').appendTo($('#modalcontainer'));
  $('#modalcontainer').addClass('p-tiredetails');
}

function onSlideshowOpenClick(evt) {
  evt.preventDefault();
  $('#tiredetails-fullscreenslider').addClass('show');
}

function onSlideshowCloseClick(evt) {
  evt.preventDefault();
  $(this).closest('.p-fullscreenslider').removeClass('show');
}

function onTiresizeChange(user)
{
  let selectedsize = dompack.qS("#tiredetails-tiresize").value;
  if(user)
    datalayer.triggerEvent("coopertires:tiredetails_rimselect", { ds_filter_rim: selectedsize });

  let numvisible = 0;
  for (let item of dompack.qSA(".p-specs__tireitems tr.p-specs__row"))
  {
    let show = (!selectedsize || item.classList.contains("p-specs__table--rim" + selectedsize));
    item.style.display = show ? "" : "none";
    $(item).toggleClass('showing', show);
    if(show)
      ++numvisible;
  }

  let numShowing = $('.p-specs__row.showing').length;
  $('.tiredetails-js-pagination').toggle(numShowing > tireSizesPerPage);
  initializeTireSizesPagination(numShowing);

  let subtitle = dompack.qS(".p-specs__subtitle");
  if(subtitle)
    subtitle.textContent = numvisible > 1 ? subtitle.dataset.multipletext.replace('###',numvisible) : subtitle.dataset.singletext;
}

function initializeTireSizesPagination(numShowing) {
  $('.tiredetails-js-pagination').pagination('destroy'); // make sure onInit gets called

  $('.tiredetails-js-pagination').pagination({
    ...util.getDefaultPaginationSettings(),
    items: numShowing,
    itemsOnPage: tireSizesPerPage,
    onPageClick: (pageNumber) => {
      togglePageNavClass(pageNumber - 1);// make it 0-based
      dompack.qS('.p-specs__table .wh-anchor').scrollIntoView({block:'start',behavior:'smooth'});
    },
    onInit: () => {
      togglePageNavClass(0);
    }
  });

  $('.tiredetails-js-pagination').toggle(numShowing > tireSizesPerPage);
}

function togglePageNavClass(pageNum = 0) {
  $('.p-specs__row.showing').each(function(idx) {
    let show = idx >= (tireSizesPerPage * pageNum) && idx < (tireSizesPerPage * (pageNum+1));
    $(this).toggleClass('hide-by-page-nav', !show);
  });
}

function setupMobileSlider() {
  let mobileSwiper = new Swiper(dompack.qS('#tiredetails-mobile-carousel'), {
    direction: 'horizontal',
    loop: true,
    speed: 500,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    lazy: {
      loadOnTransitionStart: true,
    },
  });
}

function setupExpandItems() {
  $('.p-expand__label').click(function(evt) {
    evt.preventDefault();
    $(this).parents('.p-expand').toggleClass('show');
  })
}

async function setupDealerLocator()
{
  try
  {
    let bounds = dompack.debugflags['debug'] ? new google.maps.LatLngBounds({lat:51.38494009999999,lng:-0.351468299999965},{lat:51.6723432,lng:0.14827100000002247})
                                       : await requestLocation();

    if (bounds)
    {
      let link = dompack.qS(".p-find-store__more").getAttribute('href');
      let url = new URL(link);
      url.searchParams.set("position", bounds.getCenter().toString());

      let rpc = new JSONRPC();
      let searchstart = Date.now();
      let result = await rpc.async("SearchDealersWithinBounds",
                                   { lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng() },
                                   { lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng() },
                                   whintegration.config.site.mastertype);

      let meta = { dn_dealertime: Date.now() - searchstart
                 , dn_dealerresults: 0
                 }

      if(result.success)
        meta.dn_dealerresults = result.dealers.length;

      datalayer.triggerEvent("coopertires:dealer_dealerresults", meta);

      if (result.success && result.dealers.length)
      {
        let container = dompack.qS(".p-find-store__results");
        for (let subresult of result.dealers)
        {
          // Format the distance (convert from meters to miles, add "mile(s)" suffix)
          subresult.distance = util.formatDistance(subresult.distance);

          if (!subresult.website)
          {
            url.searchParams.set("dealer", subresult.id);
            subresult.website = url.toString();
          }

          let resultDiv = domtemplate.instantiate(document.getElementById("dealer-result"), subresult).firstChild;
          container.insertBefore(resultDiv, container.lastChild);
        }
        container.style.display = "block";
      }
    }
  }
  catch (e) {console.log(e)}
  finally
  {
    dompack.qS('#find-store-loading').style.display = 'none';
  }
}

dompack.onDomReady(() => {
  $('.p-downloadrelease-open').click(evt => {
    evt.preventDefault();
    $('#tiredetails-downloadreleaseprompt').addClass('p-downloadreleaseprompt--show');
  });
  $('.p-downloadreleaseprompt__close').click(evt => {
    evt.preventDefault();
    $('#tiredetails-downloadreleaseprompt').removeClass('p-downloadreleaseprompt--show');
  });
});

dompack.register('.p-find-store', node => node.addEventListener('submit', handleLocationLookupFormSubmit));
