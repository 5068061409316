/* global Swiper */

import './lineupslideshow.scss';

import $ from 'jquery';
import { TimelineLite } from 'gsap';

export default class LineupSlideshow {
  constructor($parentContainer) {
    this.$container = $parentContainer;
  }

  setup($slideshow) {
    $slideshow.each((idx, el) => {
      let $swiperContainer = $(el).find('.swiper-container');

      let numSlides = $swiperContainer.find('.swiper-slide').length;
      $swiperContainer.find('.swiper-button-next').toggleClass('swiper-button-disabled', numSlides == 0);
      $swiperContainer.find('.swiper-button-prev').addClass('swiper-button-disabled');

      new Swiper($swiperContainer.get(0), {
        slidesPerView: 'auto',
        spaceBetween: 15,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    });
  }

  enable($slideshow) {
    let tl = new TimelineLite();

    let $currentSlideshow = this.$container.find('.lineupslideshow.enabled');

    tl.to($currentSlideshow, .4, { opacity: 0 }) // hide slideshow
      .to($currentSlideshow, 0, { css: { className:'-=enabled' } }) // disable current slideshow
      .to($slideshow, 0, { css: { opacity: 0, className:'+=enabled' },
                                  onComplete: () => this.setup($slideshow),
                                }) // enable slideshow
      .to($slideshow, 0, { y: -30 }) // position slideshow
      .to($slideshow, .4, { y: 0, opacity: 1 }); // fade in slideshow

  }
}
