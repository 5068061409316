import './news.scss';

import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import * as util from '../../shared/js/utilities';

import $ from 'jquery';
window.jQuery = $;
require('../../shared/js/jquery.pagination.js');

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-news'))
    return;

  $('.news-js-paginator').pagination({
    ...util.getDefaultPaginationSettings(),
    items: $('.p-news__newsitemcontainer').length,
    itemsOnPage: whintegration.config.obj.numperpage,
    onPageClick: (pageNumber) => {
      let pageNum = pageNumber - 1; // make it 0-based
      $('.p-news__newspage').each(function(idx) {
        $(this).toggle(idx == pageNum);
        dompack.qS('.p-news__othernews .wh-anchor').scrollIntoView({block:'start',behavior:'smooth'});
      });
      util.setupImageTextBlocks();
    },
  });
});
