import './contactinformation.scss';

import * as dompack from 'dompack';
import * as util from '../../shared/js/utilities';

let cooperLat = 51.3778438;
let cooperLng = -2.1409605;

/*
  Note: also used in dealerpages
*/

async function setupSmallMap( container )
{
  await util.googleMaps;

  if( container.dataset.location )
  {
    let latlng = container.dataset.location.split(",");
    if( latlng.length == 2 )
    {
      cooperLat = parseFloat(latlng[0]);
      cooperLng = parseFloat(latlng[1]);
    }
  }

  let dealerIcon;
  if (container && container.dataset.dealerIcon)
    dealerIcon = { url: container.dataset.dealerIcon, scaledSize: new google.maps.Size(23, 41) };

  let mapnode = container.querySelector(".p-contactinformation__map__holder");
  if( !mapnode )
    return;

  // Initialize the map
  let map = new google.maps.Map(mapnode, {
    center: { lat: cooperLat, lng: cooperLng },
    zoom: 12,
    disableDoubleClickZoom: true,
    scrollwheel: false,
    styles: util.getGoogleMapStyles(),
  });

  // Create a marker
  let latlng = new google.maps.LatLng(cooperLat, cooperLng);
  let marker = new google.maps.Marker({
    position: latlng,
    map,
    icon: dealerIcon,
    clickable: false,
  });

  for( let anode of container.querySelectorAll(".button[href]") )
    anode.setAttribute('href', `https://www.google.com/maps/dir/?api=1&destination=${cooperLat},${cooperLng}`);
}

dompack.register(".p-contactinformation__map-container", node => setupSmallMap(node));
