import * as dompack from 'dompack';
import JSONRPC from "@mod-system/js/net/jsonrpc";
import { isValidEmailAddress } from 'dompack/types/email';

class NewsletterAccountAPI
{
  constructor(options)
  {
    if(!options)
      options={};
    this.options = { server: 'server' in options ? options.server : null
                   , accounttag: 'accounttag' in options ? options.accounttag : null
                   };
    this.rpc = null;
    this.url = new URL(this.options.server || location.href).origin + '/wh_services/newsletter/accountapi';
  }
  destroy()
  {
    if(this.rpc)
      this.rpc.destroy();

    this.rpc = null;
  }
  setupSubscribeForm(form, options)
  {
    if(!options)
      options={};

    var list = 'list' in options ? options.list : form.getAttribute("data-newsletter-list");
    if(!list)
      throw new Error("setupSubscribeForm requires a 'list' option or a 'data-newsletter-list' attribute with the tag of the list to subscribe to");

    form.addEventListener("submit", (evt) => this.handleSubscribeForm(form, list, options, evt));
  }
  handleSubscribeForm(form, list, options, evt)
  {
    evt.preventDefault();
    evt.stopPropagation();

    var emailfield = form.querySelector('*[name="email"]');
    if(!emailfield)
      throw new Error(`No input field named 'email' found in form (looking for <input name="email" ... />)`);

    //ADDME replace with RPC busy handling and remove wh.ui.base load

    // add extra fields (<input>s with a data-newsletter-wrd-tag attribute)
    let extrafields = {};
    for (const extrafield of form.querySelectorAll('input[data-newsletter-wrd-tag]'))
    {
      if (extrafield.value)
        extrafields[extrafield.getAttribute('data-newsletter-wrd-tag')] = extrafield.value;
    }

    this.__subscribe(form, emailfield.value, list, extrafields).then( resultinfo => this.handleSubscribeFormFeedback(options,resultinfo), null);
  }
  handleSubscribeFormFeedback(options, resultinfo)
  {
    let lock = dompack.flagUIBusy
    if(options && options.onSubscribe)
      options.onSubscribe({ target:this, email: resultinfo.emailaddress, guid:resultinfo.guid })
  }
  subscribe(emailaddress, listtag, extrafields)
  {
    __subscribe(null, emailaddress, listtag, extrafields);
  }
  async __subscribe(form, emailaddress, listtag, extrafields)
  {
    if(!emailaddress || !isValidEmailAddress(emailaddress))
    {
      console.log("Subscription failed - invalid email address");
      throw new Error("Subscription failed - invalid email address");
    }

    //ADDME xmlhttp if cross-domain wise possible ?
    if(!this.rpc)
      this.rpc = new JSONRPC({url: this.url, requestmethod: 'jsonp'});

    if(!extrafields)
      extrafields = {};

    var resultinfo = {emailaddress:emailaddress, listtag:listtag};
    let busylock = dompack.flagUIBusy();
    try
    {
      let result = await this.rpc.async('Subscribe', location.href, this.options.accounttag||'', listtag, emailaddress, extrafields);
      if(this.rpc)
        this.rpc.destroy();
      this.rpc = null;

      resultinfo.errorcode = "OK";
      resultinfo.guid = result.guid;
      dompack.dispatchCustomEvent(form, "wh:newsletter-subscribed", { bubbles:true, cancelable: true, detail: { }});
    }
    finally
    {
      busylock.release();
    }
  }
}

var api;
dompack.register(".whplugin-newsletter-subscription", form =>
{
  if(!api)
    api = new NewsletterAccountAPI;
  api.setupSubscribeForm(form);
  form.whplugin_processed = true;
});
